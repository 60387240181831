import { useEffect } from 'react';
import type { ComponentProps } from 'react';
import { useMount } from 'react-use';

import { getErrorStatus } from '@margobank/components/common/error';
import { useIntl } from '@margobank/components/intl';
import { Column, Row } from '@margobank/components/layout';
import Loader, { useLoadingState } from '@margobank/components/Loader';
import { Body, Subheading } from '@margobank/components/text';

import { clearPhoneNumberVerificationToken, loadPairingQRcode } from 'app/auth/actions';
import { useLoadDevicesInformation } from 'app/auth/queries';
import { selectPairingQRCode, selectPhoneNumberVerificationToken } from 'app/auth/selectors';
import { useDispatch, useSelector } from 'common/store';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';
import { QRCodeImg } from '../../../_styled';

const HTTP_NOT_ALLOWED = 403;
const REFETCH_INTERVAL_DELAY_MS = 1_000;

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onConfirm: () => void;
  onPhoneNumberTokenExpired: () => void;
  userId: string;
};

const FlashCode = ({ onConfirm, onPhoneNumberTokenExpired, userId, ...rest }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const { LoadingState, watch } = useLoadingState();
  const pairingQRCode = useSelector(selectPairingQRCode);
  const phoneNumberVerificationToken = useSelector(selectPhoneNumberVerificationToken);

  const [devices] = useLoadDevicesInformation(
    { userId },
    {
      refetchInterval: (devices = []) =>
        devices.length > 0 && !!devices[0].paired ? false : REFETCH_INTERVAL_DELAY_MS,
    },
  );

  const [deviceInformation] = devices || [];

  useMount(() => {
    if (!phoneNumberVerificationToken) {
      return onPhoneNumberTokenExpired();
    }

    // Fetch the QRCode
    watch(dispatch(loadPairingQRcode(userId, phoneNumberVerificationToken))).catch((error) => {
      const statusCode = getErrorStatus(error);
      if (statusCode === HTTP_NOT_ALLOWED) {
        dispatch(clearPhoneNumberVerificationToken());
        onPhoneNumberTokenExpired();
      }
    });
  });

  useEffect(() => {
    // Device paired, go to the next step
    if (pairingQRCode && deviceInformation?.paired) {
      onConfirm();
    }
  }, [deviceInformation, onConfirm, pairingQRCode]);

  return (
    <PairingLayout
      {...rest}
      subtitle={t('auth.pairing.flashCode.subtitle')}
      title={t('auth.pairing.title')}
    >
      {!pairingQRCode ? (
        <Column alignItems="center">
          <LoadingState />
        </Column>
      ) : (
        <PairingCard spacing="s4">
          <QRCodeImg
            alt={t('auth.pairing.flashCode.qrcode')}
            height="148"
            src={pairingQRCode.qrCodeUrl}
            width="148"
          />
          <Subheading>{t('auth.pairing.flashCode.body')}</Subheading>
          {deviceInformation && (
            <Row alignItems="center" spacing="s1">
              <Loader noPadding />
              <Body subdued>
                {t('auth.pairing.flashCode.loading', { deviceName: deviceInformation.name })}
              </Body>
            </Row>
          )}
        </PairingCard>
      )}
    </PairingLayout>
  );
};

export default FlashCode;
