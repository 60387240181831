import type { InvalidateOptions, InvalidateQueryFilters, QueryKey } from 'react-query';
import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: 10_000,
    },
  },
});

declare module 'react-query' {
  interface QueryClient {
    /**
     * @deprecated This function is deprecated. Use `invalidateQueryHooks` instead.
     */
    invalidateQueries<TPageData = unknown>(
      filters?: InvalidateQueryFilters<TPageData>,
      options?: InvalidateOptions,
    ): Promise<void>;

    /**
     * @deprecated This function is deprecated. Use `invalidateQueryHooks` instead.
     */
    invalidateQueries<TPageData = unknown>(
      queryKey?: QueryKey,
      filters?: InvalidateQueryFilters<TPageData>,
      options?: InvalidateOptions,
    ): Promise<void>;
  }
}

export default queryClient;
