import { css, mediaQuery, styled } from '@memobank/style';

import HelpButtonSecondary from '../HelpButtonSecondary';

export const FullscreenHelpButton = styled(HelpButtonSecondary)`
  position: fixed;

  ${mediaQuery({ min: 'l' })(css`
    left: ${({ theme }) => theme.metric.onboardingHelpButtonLeftOffset};
    bottom: ${({ theme }) => theme.metric.onboardingHelpButtonBottomOffset};
  `)}

  ${mediaQuery({ max: 'l' })(css`
    left: ${({ theme }) => theme.spacing.s3};
    bottom: ${({ theme }) => theme.spacing.s3};
  `)}
`;
