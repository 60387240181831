import { useRouteMatch } from 'react-router-dom';

import { getGrantingHomePath } from 'components/routes/helpers';

import { useLoadGrantingFiles } from './queries';

export const useLoadGrantingFileFromRouteParam = () => {
  const match = useRouteMatch<{ hash?: string }>(getGrantingHomePath(':hash'));
  const { hash: grantingFileHash } = match?.params || {};

  const [grantingFiles] = useLoadGrantingFiles(undefined, { suspense: true });
  return grantingFiles?.find(({ hash }) => hash === grantingFileHash);
};

export const useGrantingFile = () => {
  const grantingFile = useLoadGrantingFileFromRouteParam();
  if (!grantingFile) {
    throw Error('Missing current granting file');
  }
  return grantingFile;
};
