import { useRef, useState } from 'react';

import { getErrorMessage } from '@margobank/components/common/error';
import { useIntl } from '@margobank/components/intl';
import { assertIsDefined, Enum } from '@memobank/utils';

import { useLoadPairedDeviceInformation } from 'app/auth/queries';
import { selectUser } from 'app/auth/selectors';
import { useDispatch, useSelector } from 'common/store';

import { confirmNewPairedDevice, loadUser } from '../../actions';
import { getApprovalRequestIdFromError } from '../../helpers';
import type { UserDTO } from '../../types';
import FlashCode from './_steps/FlashCode';
import Installation from './_steps/Installation';
import PairingSecondFactorValidation from './_steps/PairingSecondFactorValidation';
import Rejected from './_steps/Rejected';
import Start from './_steps/Start';
import TurnOnDevices from './_steps/TurnOnDevices';
import Validation from './_steps/Validation';

const STEP = Enum(
  'START',
  'SWITCH_ON_DEVICES',
  'CONFIRM_PHONE_NUMBER',
  'INSTALLATION',
  'FLASH_CODE',
  'SECOND_FACTOR_VALIDATION',
  'VALIDATION',
  'REJECTED',
);

type Step = Enum<typeof STEP>;

type Props = {
  onAfterPairing?: () => void;
  onCancel?: () => void;
};

const UpdateDevice = ({ onAfterPairing, onCancel }: Props) => {
  const { t } = useIntl();
  const dispatch = useDispatch();
  const approvalRequestIdRef = useRef<string>();
  const errorRef = useRef<string>();
  const newDeviceIdRef = useRef<string>();
  const [currentStep, setCurrentStep] = useState<Step>(STEP.START);
  const user = useSelector(selectUser) as UserDTO;

  const [pairedDeviceInformation, { LoadingState, refetch: refectPairedDeviceInformation }] =
    useLoadPairedDeviceInformation({ userId: user.id });

  const commonProps = {
    closeButton: onCancel && { label: t('common.actions.cancel'), onClick: onCancel },
    userId: user.id,
  };

  const tryConfirmPairedNewDevice = (deviceId: string, approvalRequestId?: string) => {
    return dispatch(confirmNewPairedDevice(user.id, deviceId, approvalRequestId)).then(
      async (_success) => {
        await refectPairedDeviceInformation();
        setCurrentStep(STEP.VALIDATION);
      },
      (error) => {
        const id = getApprovalRequestIdFromError(error);
        if (id) {
          approvalRequestIdRef.current = id;
          setCurrentStep(STEP.SECOND_FACTOR_VALIDATION);
        } else {
          throw error;
        }
      },
    );
  };

  const handleConfirmFlashCode = (deviceId: string) => {
    newDeviceIdRef.current = deviceId;
    tryConfirmPairedNewDevice(deviceId);
  };

  const handleExpiredDeviceReplacementToken = () => {
    errorRef.current = t('auth.updatePairing.expiredError');
    setCurrentStep(STEP.START);
  };

  const handleSecondFactorApproved = () => {
    assertIsDefined(approvalRequestIdRef.current);
    assertIsDefined(newDeviceIdRef.current);

    tryConfirmPairedNewDevice(newDeviceIdRef.current, approvalRequestIdRef.current).catch(
      (error) => {
        errorRef.current = getErrorMessage(error);
        setCurrentStep(STEP.START);
      },
    );
  };

  const handleSecondFactorError = () => {
    errorRef.current = t('auth.updatePairing.expiredError');
    setCurrentStep(STEP.START);
  };

  const handleSecondFactorRejected = () => setCurrentStep(STEP.REJECTED);

  const handleSuccessConfirmation = () => {
    dispatch(loadUser()).then(() => {
      onAfterPairing?.();
    });
  };

  const renderFlashCode = () => (
    <FlashCode
      {...commonProps}
      onConfirm={handleConfirmFlashCode}
      onPrevious={() => setCurrentStep(STEP.INSTALLATION)}
      onTokenExpired={handleExpiredDeviceReplacementToken}
    />
  );

  const renderInstallation = () => (
    <Installation
      {...commonProps}
      onConfirm={() => setCurrentStep(STEP.FLASH_CODE)}
      onPrevious={() => setCurrentStep(STEP.SWITCH_ON_DEVICES)}
    />
  );

  const renderRejected = () => (
    <Rejected {...commonProps} onRetry={() => setCurrentStep(STEP.START)} />
  );

  const renderSecondFactorValidation = () => {
    assertIsDefined(approvalRequestIdRef.current);
    assertIsDefined(pairedDeviceInformation);

    return (
      <PairingSecondFactorValidation
        approvalRequestId={approvalRequestIdRef.current}
        deviceName={pairedDeviceInformation.name ?? ''}
        onApproved={handleSecondFactorApproved}
        onError={handleSecondFactorError}
        onRejected={handleSecondFactorRejected}
        userId={user.id}
      />
    );
  };

  const renderStart = () => {
    assertIsDefined(pairedDeviceInformation);

    return (
      <Start
        {...commonProps}
        deviceName={pairedDeviceInformation.name ?? ''}
        error={errorRef.current}
        onConfirm={() => {
          errorRef.current = undefined;
          setCurrentStep(STEP.SWITCH_ON_DEVICES);
        }}
      />
    );
  };

  const renderTurnOnDevices = () => (
    <TurnOnDevices
      {...commonProps}
      onConfirm={() => setCurrentStep(STEP.INSTALLATION)}
      onPrevious={() => setCurrentStep(STEP.START)}
    />
  );

  const renderValidation = () => (
    <Validation {...commonProps} onConfirm={handleSuccessConfirmation} />
  );

  if (!pairedDeviceInformation) {
    return <LoadingState />;
  }

  return (
    <>
      {currentStep === STEP.START && renderStart()}
      {currentStep === STEP.SWITCH_ON_DEVICES && renderTurnOnDevices()}
      {currentStep === STEP.INSTALLATION && renderInstallation()}
      {currentStep === STEP.FLASH_CODE && renderFlashCode()}
      {currentStep === STEP.SECOND_FACTOR_VALIDATION && renderSecondFactorValidation()}
      {currentStep === STEP.VALIDATION && renderValidation()}
      {currentStep === STEP.REJECTED && renderRejected()}
    </>
  );
};

export default UpdateDevice;
