import { useCallback, useMemo } from 'react';

import { LoadingStates } from '@margobank/components/Loader';

import {
  useLoadPairedDeviceInformation,
  useLoadTemporaryUser,
  useLoadUser,
} from 'app/auth/queries';

type UsePairedDeviceInfoArgs = {
  enabled?: boolean;
  token?: string;
};

export const usePairedDeviceInfo = ({ enabled = true, token }: UsePairedDeviceInfoArgs) => {
  const [connectedUser, connectedUserQuery] = useLoadUser(undefined, { enabled });
  const [temporaryUser, temporaryUserQuery] = useLoadTemporaryUser(
    { token: token as string },
    { enabled: enabled && !!token },
  );
  const userId = token ? temporaryUser?.id : connectedUser?.id;

  const [pairedDevice, pairedDeviceQuery] = useLoadPairedDeviceInformation(
    { userId: userId as string },
    {
      enabled: enabled && !!userId,
    },
  );
  const deviceName = pairedDevice?.name ?? temporaryUser?.pairedDevice?.name;

  const userQuery = useMemo(
    () => (token ? temporaryUserQuery : connectedUserQuery),
    [connectedUserQuery, temporaryUserQuery, token],
  );

  const LoadingState = useCallback(() => {
    return <LoadingStates queries={[userQuery, pairedDeviceQuery]} />;
  }, [pairedDeviceQuery, userQuery]);

  return [
    {
      userId,
      deviceName,
    },
    {
      error: userQuery.error || pairedDeviceQuery.error,
      errorMessage: userQuery.errorMessage || pairedDeviceQuery.errorMessage,
      isLoading: userQuery.isLoading || pairedDeviceQuery.isLoading,
      LoadingState,
    },
  ] as const;
};
