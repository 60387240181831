import { create as createStore } from 'zustand';
import { persist } from 'zustand/middleware';
import { useShallow } from 'zustand/react/shallow';

import { getErrorMessage } from '@margobank/components/common/error';
import type { Locale } from '@margobank/components/common/types';
import { useSnackbar } from '@margobank/components/snackbar';

import { DEFAULT_LOCALE } from 'common/constants';

import { useUpdateUserLocale } from './mutations';

const LOCAL_STORAGE_KEY = 'auth';

export type AuthStore = {
  fingerprintId: string | null;
  forcedLocale: Locale | null; // The locale changed by the user.
  getLocale: () => Locale;
  setFingerprintId: (fingerprintId: string) => void;
  setForcedLocale: (locale: Locale) => void;
};

export const useAuthStore = createStore<AuthStore>()(
  persist(
    (set, get) => ({
      fingerprintId: null,
      forcedLocale: null,
      getLocale: () => get().forcedLocale ?? DEFAULT_LOCALE,
      setFingerprintId: (fingerprintId) => set({ fingerprintId }),
      setForcedLocale: (forcedLocale) => set({ forcedLocale }),
    }),
    {
      name: LOCAL_STORAGE_KEY,
      partialize: ({ fingerprintId, forcedLocale }) => ({ fingerprintId, forcedLocale }),
    },
  ),
);

export const useFingerprint = () => {
  return useAuthStore(
    useShallow(({ fingerprintId, setFingerprintId }) => [fingerprintId, setFingerprintId] as const),
  );
};

export const useLocale = () => {
  const { forcedLocale, getLocale, setForcedLocale } = useAuthStore(
    useShallow(({ forcedLocale, getLocale, setForcedLocale }) => ({
      forcedLocale,
      getLocale,
      setForcedLocale,
    })),
  );

  const locale = getLocale();

  const { displayError } = useSnackbar();
  const [updateLocale] = useUpdateUserLocale();

  /**
   * After a session creation, we need to synchronize the locale
   * between frontend and backend.
   */
  const synchronizeLocale = (userLocale: Locale) => {
    if (forcedLocale && userLocale !== forcedLocale) {
      // The user changed the locale before the login, save the new preference
      updateUserLocale(forcedLocale);
    } else if (!forcedLocale) {
      // No locale forced / persisted by the user on this browser, we use the user's.
      // eg. A user locale is "en" (backend side), if he logs in from a new browser in "fr",
      // language will switch to "en" after the login.
      setForcedLocale(userLocale);
    }
  };

  /**
   * Update backend locale for the current user and then,
   * update the frontend locale.
   */
  const updateUserLocale = (newLocale: Locale) => {
    return updateLocale({ locale: newLocale }).then(
      () => setForcedLocale(newLocale),
      (error) => displayError(getErrorMessage(error)),
    );
  };

  return {
    locale,
    forcedLocale,
    setForcedLocale,
    synchronizeLocale,
    updateUserLocale,
  };
};
