import throttle from 'lodash/throttle';
import { applyMiddleware, compose, createStore } from 'redux';
import type { FormStateMap } from 'redux-form';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import type { AuthState } from 'app/auth/reducer';
import { loadState, saveState } from 'common/localStorage';
import rootReducer from 'common/reducers';

export type State = {
  auth: AuthState;
  form: FormStateMap;
};

export const initialState = {
  auth: {},
  form: {},
};

export * from './hooks';
export * from './types';

const PERSIST_STORE_DELAY = 1000;

const enhancers: any = [];
const middleware = [thunk, promiseMiddleware];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const persistedState = loadState() || {};
const store = createStore(rootReducer, { ...initialState, ...persistedState }, composedEnhancers);

store.subscribe(
  throttle(() => {
    const { auth } = store.getState();
    const { sessionToken } = { ...auth };

    saveState({
      auth: {
        sessionToken,
      },
    });
  }, PERSIST_STORE_DELAY),
);
export default store;
