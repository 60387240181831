import { createContext, useCallback, useContext, useMemo } from 'react';
import type { ReactNode } from 'react';
import TagManager from 'react-gtm-nonce';
import { useMount } from 'react-use';
import type { Metric } from 'web-vitals';

type DataLayer =
  | Partial<{
      environment: string;
      isLoggedIn: boolean;
      release: string;
    }>
  | {
      event: 'custom.pageView';
      page: string;
    }
  | {
      event: 'custom.webVitals';
      eventAction: string;
      eventCategory: string;
      eventLabel: string;
      eventValue: number;
      page: string;
    };

type GTMContextType = {
  pushDataLayer: (data: DataLayer) => void;
  sendPageView: (page: string) => void;
  sendWebVitals: (metric: Metric, page: string) => void;
};

type Props = {
  children: ReactNode;
  gtmId?: string;
  initialDataLayer?: DataLayer;
};

const CSP_NONCE = document.querySelector('[data-csp-nonce]')?.getAttribute('data-csp-nonce');

const GTMContext = createContext<GTMContextType | undefined>(undefined);

export const GTMProvider = ({ children, gtmId, initialDataLayer }: Props) => {
  useMount(() => {
    if (gtmId) {
      TagManager.initialize({
        gtmId,
        dataLayer: initialDataLayer,
        nonce: CSP_NONCE,
      });
    }
  });

  const pushDataLayer = useCallback((dataLayer: DataLayer) => {
    TagManager.dataLayer({ dataLayer, nonce: CSP_NONCE });
  }, []);

  const sendPageView = useCallback(
    (page: string) => pushDataLayer({ event: 'custom.pageView', page }),
    [pushDataLayer],
  );

  const sendWebVitals = useCallback(
    ({ delta, name }: Metric, page: string) => {
      pushDataLayer({
        event: 'custom.webVitals',
        eventAction: name,
        eventCategory: 'Web Vitals',
        eventLabel: page,
        // Google Analytics metrics must be integers, so the value is rounded.
        // For CLS the value is first multiplied by 1000 for greater precision
        eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
        page,
      });
    },
    [pushDataLayer],
  );

  const contextValue = useMemo(
    () => ({
      pushDataLayer,
      sendPageView,
      sendWebVitals,
    }),
    [pushDataLayer, sendPageView, sendWebVitals],
  );

  return <GTMContext.Provider value={contextValue}>{children}</GTMContext.Provider>;
};

export const useGTM = () => {
  const context = useContext(GTMContext);
  if (!context) {
    throw Error('useGTM should be used inside a GTMProvider');
  }
  return context;
};
