import { createContext, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';

import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { BackendPermission } from '@margobank/components/domain/user';

type PermissionsContextType = {
  hasPermission: (permission: BackendPermission | BackendPermission[]) => boolean;
  permissions: BackendPermission[];
};

type Props = {
  children: ReactNode;
  customer?: AuthenticatedCustomerDTO;
};

export const PermissionsContext = createContext<PermissionsContextType | null>(null);

const PermissionsProvider = ({ children, customer }: Props) => {
  const permissions = useMemo(
    () => customer?.user?.permissions ?? [],
    [customer?.user?.permissions],
  );

  // If `permission` is an array, check that user has at least one of those permissions
  const hasPermission = useCallback(
    (permission: BackendPermission | BackendPermission[]) =>
      Array.isArray(permission)
        ? permission.some((perm) => permissions.includes(perm))
        : permissions.includes(permission),
    [permissions],
  );

  return (
    <PermissionsContext.Provider value={{ hasPermission, permissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;
