declare global {
  interface Window {
    env: Env;
  }
}

export type Env = {
  AUTOMATION_API_ROOT: string;
  BACKEND_API_ROOT: string;
  ENVIRONMENT: 'development' | 'integration' | 'preproduction' | 'production' | 'test';
  GOOGLE_TAG_MANAGER_ID?: string;
  HELPSCOUT_BEACON_ID: string;
  PRISMIC_API_ENDPOINT: string;
  RELEASE: string;
  SENTRY_DSN?: string;
  YOUSIGN_MULTIPLE_CONTRACTS_SIGNATURE_UI: string;
  YOUSIGN_PROXY_CONTRACT_SIGNATURE_UI: string;
  YOUSIGN_SINGLE_CONTRACT_SIGNATURE_UI: string;
};

const { env } = window;

export const RELEASE = import.meta.env.VITE_APP_RELEASE || '';

export const {
  AUTOMATION_API_ROOT,
  BACKEND_API_ROOT,
  ENVIRONMENT,
  GOOGLE_TAG_MANAGER_ID,
  HELPSCOUT_BEACON_ID,
  PRISMIC_API_ENDPOINT,
  SENTRY_DSN,
  YOUSIGN_MULTIPLE_CONTRACTS_SIGNATURE_UI,
  YOUSIGN_PROXY_CONTRACT_SIGNATURE_UI,
  YOUSIGN_SINGLE_CONTRACT_SIGNATURE_UI,
} = env;
