import type { ReportHandler } from 'web-vitals';

const reportWebVitals = (onReport?: ReportHandler) => {
  if (onReport && onReport instanceof Function) {
    import('web-vitals').then(({ getCLS, getFCP, getFID, getLCP, getTTFB }) => {
      getCLS(onReport);
      getFID(onReport);
      getFCP(onReport);
      getLCP(onReport);
      getTTFB(onReport);
    });
  }
};

export default reportWebVitals;
