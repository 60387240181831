import { useContext } from 'react';

import { HelpBeaconContext } from './HelpBeaconProvider';

const useHelpBeacon = () => {
  const context = useContext(HelpBeaconContext);
  if (!context) {
    throw Error('You should not use useHelpBeacon outside a <HelpBeaconProvider />');
  }
  return context;
};

export default useHelpBeacon;
