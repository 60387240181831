import sortBy from 'lodash/sortBy';

import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';

export const getCustomerHomePath = (hash?: string) => `/${hash}`;

export const getGrantingHomePath = (hash?: string) => `/onboarding/${hash}`;

export const getLastActiveCustomerHomePath = (customers: AuthenticatedCustomerDTO[]) => {
  const lastActiveCustomer = getLastActiveWorkspace(customers);
  return getCustomerHomePath(lastActiveCustomer.hash);
};

export const getLastActiveGrantingHomePath = (grantingFiles: GrantingFileDTO[]) => {
  const lastActiveGrantingFile = getLastActiveWorkspace(grantingFiles);
  return getGrantingHomePath(lastActiveGrantingFile.hash);
};

// A workspace can be a granting file or a customer.
export const getLastActiveWorkspace = <T extends AuthenticatedCustomerDTO | GrantingFileDTO>(
  workspaces: T[],
) => {
  const sortedWorkspaces = sortBy(workspaces, ({ lastAccess }) => lastAccess).reverse();
  const [lastActiveWorkspace] = sortedWorkspaces;
  return lastActiveWorkspace;
};
