import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { ComponentType, ReactNode } from 'react';
import { useUnmount } from 'react-use';

import Modal from '@margobank/components/Modal';

import { usePairedDeviceInfo } from './queries';
import SecondFactorValidationFlow from './SecondFactorValidationFlow';

export const TEST_ID_SECOND_FACTOR_VALIDATION_MODAL = 'second-factor-validation-modal';

type MutationFn = () => Promise<any>;

export type SecondFactorValidationOptions = {
  getTitle?: (args: { deviceName: string }) => string;
  withModal?: boolean;
};

export type SecondFactorValidationParams = {
  approvalRequestId: string;
  token?: string;
};

type SecondFactorValidationContextType = readonly [
  waitForSecondFactorValidation: (
    params: SecondFactorValidationParams,
    mutationFn: MutationFn,
    options: SecondFactorValidationOptions,
  ) => Promise<unknown>,
  meta: {
    SecondFactorValidationOutlet: ComponentType;
    isWaitingForSecondFactorValidation: boolean;
  },
];

const SecondFactorValidationContext = createContext<SecondFactorValidationContextType | null>(null);

type Props = {
  children: ReactNode;
};

const SecondFactorValidationProvider = ({ children }: Props) => {
  const resolveRef = useRef<(value?: unknown) => void>();
  const rejectRef = useRef<(reason?: any) => void>();
  const [isWaitingForSecondFactorValidation, setIsWaitingForSecondFactorValidation] =
    useState(false);

  const mutationFnRef = useRef<MutationFn>();
  const optionsRef = useRef<SecondFactorValidationOptions>();
  const paramsRef = useRef<SecondFactorValidationParams>();

  const [, { isLoading: isLoadingDeviceInfo }] = usePairedDeviceInfo({
    token: paramsRef.current?.token,
    enabled: isWaitingForSecondFactorValidation,
  });

  const cancelSecondFactorValidation = useCallback(() => {
    setIsWaitingForSecondFactorValidation(false);
    mutationFnRef.current = undefined;
    optionsRef.current = undefined;
    paramsRef.current = undefined;
    resolveRef.current = undefined;
    rejectRef.current = undefined;
  }, [setIsWaitingForSecondFactorValidation]);

  const waitForSecondFactorValidation = useCallback(
    (
      params: SecondFactorValidationParams,
      mutationFn: MutationFn,
      options: SecondFactorValidationOptions,
    ) => {
      mutationFnRef.current = mutationFn;
      optionsRef.current = options;
      paramsRef.current = params;
      setIsWaitingForSecondFactorValidation(true);

      return new Promise((resolve, reject) => {
        resolveRef.current = resolve;
        rejectRef.current = reject;
      });
    },
    [setIsWaitingForSecondFactorValidation],
  );

  const handleApprove = useCallback(() => {
    return Promise.resolve(mutationFnRef.current?.())
      .then(resolveRef.current, rejectRef.current)
      .finally(() => setIsWaitingForSecondFactorValidation(false));
  }, [setIsWaitingForSecondFactorValidation]);

  const handleCancel = useCallback(() => {
    setIsWaitingForSecondFactorValidation(false);
    return rejectRef.current?.({});
  }, [setIsWaitingForSecondFactorValidation]);

  const SecondFactorValidationOutlet = useCallback(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useUnmount(() => {
      cancelSecondFactorValidation();
    });

    return paramsRef.current ? (
      <SecondFactorValidationFlow
        onApprove={handleApprove}
        onCancel={handleCancel}
        options={optionsRef.current}
        params={paramsRef.current}
      />
    ) : null;
  }, [cancelSecondFactorValidation, handleApprove, handleCancel]);

  const memoizedValue = useMemo(
    () =>
      [
        waitForSecondFactorValidation,
        {
          SecondFactorValidationOutlet,
          isWaitingForSecondFactorValidation:
            isWaitingForSecondFactorValidation && !isLoadingDeviceInfo,
        },
      ] as const,
    [
      SecondFactorValidationOutlet,
      isLoadingDeviceInfo,
      isWaitingForSecondFactorValidation,
      waitForSecondFactorValidation,
    ],
  );

  return (
    <SecondFactorValidationContext.Provider value={memoizedValue}>
      {children}
      {/* Render SecondFactorValidationOutlet in a modal if `withModal` option is true */}
      {!!optionsRef.current?.withModal && (
        <Modal
          data-testid={TEST_ID_SECOND_FACTOR_VALIDATION_MODAL}
          hasPriority
          isOpen={isWaitingForSecondFactorValidation && !isLoadingDeviceInfo}
          onRequestClose={handleCancel}
          shouldCloseOnOverlayClick={false}
          withCloseButton
        >
          <SecondFactorValidationOutlet />
        </Modal>
      )}
    </SecondFactorValidationContext.Provider>
  );
};

export default SecondFactorValidationProvider;

export const useSecondFactorValidation = (defaultOptions?: SecondFactorValidationOptions) => {
  const context = useContext(SecondFactorValidationContext);
  if (!context) {
    throw Error('useSecondFactorValidation must be used inside a <SecondFactorValidationProvider>');
  }
  const [waitForSecondFactorValidation, secondFactorValidationMeta] = context;

  const waitForSecondFactorValidationWithOptions = (
    params: SecondFactorValidationParams,
    mutationFn: MutationFn,
    options?: SecondFactorValidationOptions,
  ) =>
    waitForSecondFactorValidation(params, mutationFn, {
      withModal: true,
      ...defaultOptions,
      ...options,
    });

  return [waitForSecondFactorValidationWithOptions, secondFactorValidationMeta] as const;
};
