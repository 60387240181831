import type { TranslationKey } from 'translations';

import { OPTIONAL_FEATURE } from '@margobank/components/domain/customer/constants';
import { BACKEND_PERMISSION, USER_ACCESS_ROLE } from '@margobank/components/domain/user';
import type { IconName } from '@memobank/ui';
import { Enum } from '@memobank/utils';

import type { RouteAccessHelpers } from './hooks';
import { useRouteAccessHelpers } from './hooks';

export const ROUTE_GROUP = Enum('ACCOUNTING', 'API', 'FINANCE', 'INVESTMENT', 'MANAGEMENT');
export const ROUTE_TYPE = Enum('LINK', 'HELP_BUTTON');

type MenuItem = {
  group?: Enum<typeof ROUTE_GROUP>;
  hasAccess?: (args: RouteAccessHelpers) => boolean;
  icon: IconName;
  id: string;
  label: TranslationKey;
  labelShort?: TranslationKey;
  type: Enum<typeof ROUTE_TYPE>;
};

export const CUSTOMER_ROUTE = {
  ACCOUNT: 'account',
  ACCOUNTING_MAPPINGS: 'accounting/mappings',
  ACCOUNTING_OPERATIONS: 'accounting/operations',
  ACCOUNTING_SUPPLIERS: 'accounting/suppliers',
  API: 'api',
  CARDS: 'cards',
  COLLECTIONS: 'collections',
  COMPANY_SETTINGS: 'company-settings',
  DASHBOARD: 'dashboard',
  DEBT_PURCHASE_FINANCING_DETAILS: 'financing/details/debt-purchase',
  DOCUMENTS: 'documents',
  FINANCING: 'financing',
  LOAN_FINANCING_DETAILS: 'financing/details',
  OUTGOING_COLLECTIONS: 'outgoing-collections',
  PAYMENT: 'payment',
  TERM_DEPOSITS: 'term-deposits',
  TRANSFER_REQUESTS: 'transfer-requests',
  USER_SETTINGS: 'user-settings',
  USERS: 'users',
} as const;

export const CUSTOMER_ROUTES = Object.values(CUSTOMER_ROUTE);

export const menuItems: MenuItem[] = [
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.ACCOUNT,
    icon: 'large/wallet',
    label: 'customer.nav.accounts',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.CURRENT_ACCOUNTS_READ),
  },
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.CARDS,
    icon: 'large/credit-card',
    label: 'customer.nav.cards',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasOptionalFeature, hasPermission }) =>
      hasPermission(BACKEND_PERMISSION.CARDS_READ) && hasOptionalFeature(OPTIONAL_FEATURE.CARDS),
  },
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.PAYMENT,
    icon: 'large/arrows',
    label: 'customer.nav.payment',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) =>
      hasPermission(BACKEND_PERMISSION.BENEFICIARIES_READ) ||
      hasPermission(BACKEND_PERMISSION.CURRENT_ACCOUNTS_READ) ||
      hasPermission(BACKEND_PERMISSION.TRANSFERS_WRITE),
  },
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.TRANSFER_REQUESTS,
    icon: 'large/user_with-arrows',
    label: 'customer.nav.transferRequests',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) =>
      hasPermission(BACKEND_PERMISSION.TRANSFER_REQUESTS_READ) ||
      hasPermission(BACKEND_PERMISSION.TRANSFER_REQUESTS_VALIDATE),
  },
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.COLLECTIONS,
    icon: 'large/rectangle_with-arrow-out',
    label: 'customer.nav.collections',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.COLLECTIONS_READ),
  },
  {
    group: ROUTE_GROUP.FINANCE,
    id: CUSTOMER_ROUTE.OUTGOING_COLLECTIONS,
    icon: 'large/rectangle_with-arrow-in',
    label: 'customer.nav.outgoingCollections',
    labelShort: 'customer.nav.outgoingCollectionsShort',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasOptionalFeature, hasPermission, hasRole }) =>
      (hasPermission(BACKEND_PERMISSION.OUTGOING_COLLECTIONS_READ) &&
        hasOptionalFeature(OPTIONAL_FEATURE.OUTGOING_COLLECTIONS)) ||
      hasRole(USER_ACCESS_ROLE.OWNER) ||
      hasRole(USER_ACCESS_ROLE.ADMIN),
  },
  {
    group: ROUTE_GROUP.INVESTMENT,
    id: CUSTOMER_ROUTE.FINANCING,
    icon: 'large/vault',
    label: 'customer.nav.financing',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.FINANCES_READ),
  },
  {
    group: ROUTE_GROUP.INVESTMENT,
    id: CUSTOMER_ROUTE.TERM_DEPOSITS,
    icon: 'large/finance',
    label: 'customer.nav.termDeposits',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.INVESTMENTS_READ),
  },
  {
    group: ROUTE_GROUP.MANAGEMENT,
    id: CUSTOMER_ROUTE.USERS,
    icon: 'large/user',
    label: 'customer.nav.users',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.USER_ACCESSES_READ),
  },
  {
    group: ROUTE_GROUP.MANAGEMENT,
    id: CUSTOMER_ROUTE.DOCUMENTS,
    icon: 'large/document',
    label: 'customer.nav.documents',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.DOCUMENTS_READ),
  },
  {
    group: ROUTE_GROUP.MANAGEMENT,
    id: CUSTOMER_ROUTE.COMPANY_SETTINGS,
    icon: 'large/building',
    label: 'customer.nav.companySettings',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.COMPANIES_READ),
  },
  {
    group: ROUTE_GROUP.API,
    id: CUSTOMER_ROUTE.API,
    icon: 'large/console',
    label: 'customer.nav.api',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission, hasRole }) =>
      hasPermission(BACKEND_PERMISSION.MEMO_BANK_API_WRITE) ||
      hasRole(USER_ACCESS_ROLE.OWNER) ||
      hasRole(USER_ACCESS_ROLE.ADMIN),
  },
  {
    group: ROUTE_GROUP.ACCOUNTING,
    id: CUSTOMER_ROUTE.ACCOUNTING_SUPPLIERS,
    icon: 'large/truck',
    label: 'customer.nav.suppliers',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.ACCOUNTING_WRITE),
  },
  {
    group: ROUTE_GROUP.ACCOUNTING,
    id: CUSTOMER_ROUTE.ACCOUNTING_MAPPINGS,
    icon: 'large/spreadsheet',
    label: 'customer.nav.accountingMappings',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.ACCOUNTING_WRITE),
  },
  {
    group: ROUTE_GROUP.ACCOUNTING,
    id: CUSTOMER_ROUTE.ACCOUNTING_OPERATIONS,
    icon: 'large/calculator',
    label: 'customer.nav.accountingOperations',
    type: ROUTE_TYPE.LINK,
    hasAccess: ({ hasPermission }) => hasPermission(BACKEND_PERMISSION.ACCOUNTING_WRITE),
  },
  {
    id: 'help',
    icon: 'large/buoy',
    label: 'common.helpBeacon.title',
    type: ROUTE_TYPE.HELP_BUTTON,
  },
];

export const useMenu = () => {
  const routeAccessHelpers = useRouteAccessHelpers();

  const navItems = menuItems.filter(({ hasAccess }) => !hasAccess || hasAccess(routeAccessHelpers));
  const navLinks = navItems.filter(({ type }) => type === ROUTE_TYPE.LINK);

  return {
    navLinks: navLinks.length > 0 ? navLinks : [menuItems[0]],
    navItems,
  };
};
