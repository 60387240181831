import { Redirect } from 'react-router-dom';

import { selectUser } from 'app/auth/selectors';
import SetupUser from 'app/auth/SetupUser';
import { useSelector } from 'common/store';

import type { RouteProps } from '.';
import { Route } from '.';

type Props = RouteProps & {
  withSetupSteps?: boolean;
  withVerificationSteps?: boolean;
};

const PrivateRoute = ({
  component: Component,
  location,
  withSetupSteps,
  withVerificationSteps,
  ...rest
}: Props) => {
  const user = useSelector(selectUser);

  if (!user) {
    // `user` will either be `null` after a logout or a session expiration, or `undefined` otherwise.
    if (user === undefined) {
      return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
    }
    return <Redirect to={{ pathname: '/login' }} />;
  }

  const hasSetupStepsToFill = user.missingSetupSteps.length && withSetupSteps;
  const hasValidationStepsToFill = user.missingValidationSteps.length && withVerificationSteps;

  if (hasSetupStepsToFill || hasValidationStepsToFill) {
    return (
      <SetupUser
        user={user}
        withSetupSteps={withSetupSteps}
        withVerificationSteps={withVerificationSteps}
      />
    );
  }

  if (Component) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }

  return <Route {...rest} />;
};

export default PrivateRoute;
