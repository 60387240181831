import Button from '@margobank/components/Button/Button';
import { borderify, css, styled } from '@memobank/style';
import { Icon } from '@memobank/ui';

const activeStyle = css`
  ${({ theme }) => borderify(theme.color.white)};
  color: ${({ theme }) => theme.color.white};

  ${Icon} {
    color: currentColor;
  }
`;

const HelpButtonPrimary = styled(Button).attrs({ iconPrefix: 'medium/buoy' })<{
  isActive?: boolean;
}>`
  ${({ theme }) => borderify(theme.color.reversedGrayAccentedBackground)};
  color: ${({ theme }) => theme.color.reversedGraySubdued};

  ${Icon} {
    color: ${({ theme }) => theme.color.reversedGraySubdued};
  }

  &:not(:disabled) {
    &:hover,
    &:active {
      ${activeStyle}
    }
  }

  ${(props) => props.isActive && activeStyle}
`;

export default HelpButtonPrimary;
