import get from 'lodash/get';

import type { ErrorResponse } from '@margobank/components/common/types';
import type {
  ValidationStepConfirmEmailDTO,
  ValidationStepConfirmPhoneNumberDTO,
  ValidationStepDTO,
} from '@margobank/components/domain/user';

import { SETUP_STEP } from './constants';
import type { SetupStep, UserDTO } from './types';

export type ApprovalRequestParts = {
  id?: string;
  phone?: string;
  token?: string;
};

export const getApprovalRequestPartsFromError = (
  errorResponse: ErrorResponse,
): ApprovalRequestParts => {
  const authenticateHeader = get(errorResponse, 'response.headers.www-authenticate') || '';
  const [, id] = authenticateHeader.match(/id="(.*?)"/) || [];
  const [, token] = authenticateHeader.match(/token="(.*?)"/) || [];
  const [, phone] = authenticateHeader.match(/phone="(.*?)"/) || [];
  return { id, phone, token };
};

export const getApprovalRequestIdFromError = (errorResponse: ErrorResponse): string | undefined => {
  const { id } = getApprovalRequestPartsFromError(errorResponse);
  return id;
};

export const getSetupStepsAsBooleans = (setupSteps: SetupStep[]) => {
  return {
    shouldAcceptTerms: setupSteps.includes(SETUP_STEP.ACCEPT_TERMS),
    shouldPair2FADevice: setupSteps.includes(SETUP_STEP.PAIR_2FA_DEVICE),
    shouldSetupPassword: setupSteps.includes(SETUP_STEP.SETUP_PASSWORD),
    shouldUploadIdentityProof: setupSteps.includes(SETUP_STEP.UPLOAD_IDENTITY_PROOF),
  };
};

export const getUnverifiedEmail = (user: UserDTO) => {
  const emailValidationStep = user.missingValidationSteps.find(
    ({ data }) => 'email' in data,
  ) as unknown as ValidationStepDTO;

  if (emailValidationStep) {
    return (emailValidationStep.data as ValidationStepConfirmEmailDTO).email;
  }

  return;
};

export const getUnverifiedPhoneNumber = (user: UserDTO) => {
  const phoneNumberValidationStep = user.missingValidationSteps.find(
    ({ data }) => 'phoneNumber' in data,
  ) as unknown as ValidationStepDTO;

  if (phoneNumberValidationStep) {
    return (phoneNumberValidationStep.data as ValidationStepConfirmPhoneNumberDTO).phoneNumber;
  }

  return;
};
