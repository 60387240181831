import { useEffect, useLayoutEffect, useRef } from 'react';
import type { ComponentProps, ReactNode } from 'react';

import { Card } from '@margobank/components/card';
import { useIntl } from '@margobank/components/intl';
import { Column, Row } from '@margobank/components/layout';
import Loader from '@margobank/components/Loader';
import { Body } from '@margobank/components/text';
import { Sticker } from '@memobank/ui';

import { APPROVAL_REQUEST_STATE } from 'app/auth/constants';
import { useLoadApprovalRequest, useLoadPairedDeviceInformation } from 'app/auth/queries';
import { selectUser } from 'app/auth/selectors';
import type { UserDTO } from 'app/auth/types';
import { useSelector } from 'common/store';

const REFETCH_INTERVAL_MS = 1_000;

type RenderTitleProps = {
  deviceName: string;
};

type Props = {
  approvalRequestId: string;
  asCard?: boolean;
  deviceName?: string;
  onApproved: () => void;
  onError: () => void;
  onRejected: () => void;
  padding?: ComponentProps<typeof Column>['padding'];
  refetchInBackground?: boolean;
  renderTitle: (args: RenderTitleProps) => ReactNode;
  token?: string;
  userId?: string;
};

const SecondFactorValidation = ({
  approvalRequestId,
  asCard,
  deviceName: deviceNameProp,
  onApproved,
  onError,
  onRejected,
  padding = asCard ? 's6' : 's3',
  renderTitle,
  token,
  userId,
  refetchInBackground = false,
}: Props) => {
  const { t } = useIntl();

  const user = useSelector(selectUser) as UserDTO;

  const [approvalRequest, { LoadingState, error }] = useLoadApprovalRequest(
    {
      approvalRequestId,
      token,
      userId: userId || user.id,
    },
    {
      refetchInterval: (approvalRequest) => {
        const shouldRefetch =
          !approvalRequest?.state || approvalRequest.state === APPROVAL_REQUEST_STATE.PENDING;
        return shouldRefetch ? REFETCH_INTERVAL_MS : false;
      },
      refetchIntervalInBackground: refetchInBackground,
    },
  );

  const [pairedDeviceInformation] = useLoadPairedDeviceInformation(
    { userId: userId || user.id },
    { enabled: !deviceNameProp },
  );

  const { state = undefined } = approvalRequest || {};
  const { name: pairedDeviceName } = pairedDeviceInformation || {};
  const deviceName = pairedDeviceName ?? deviceNameProp;

  // We use refs to prevent this component from re-rendering when callbacks are not memoized
  const onApprovedRef = useRef(onApproved);
  const onErrorRef = useRef(onError);
  const onRejectedRef = useRef(onRejected);

  useLayoutEffect(() => {
    onApprovedRef.current = onApproved;
    onErrorRef.current = onError;
    onRejectedRef.current = onRejected;
  });

  useEffect(() => {
    switch (state) {
      case APPROVAL_REQUEST_STATE.APPROVED:
        onApprovedRef.current();
        break;

      case APPROVAL_REQUEST_STATE.REJECTED:
        onRejectedRef.current();
        break;

      case APPROVAL_REQUEST_STATE.CANCELLED:
      case APPROVAL_REQUEST_STATE.CONSUMED:
      case APPROVAL_REQUEST_STATE.EXPIRED:
        onErrorRef.current();
        break;

      default:
        if (error) {
          onErrorRef.current();
        }
        break;
    }
  }, [error, state]);

  if (typeof approvalRequest === 'undefined' || typeof deviceName === 'undefined') {
    return (
      <Column alignItems="center">
        <LoadingState />
      </Column>
    );
  }

  const Component = asCard ? Card : Column;

  return (
    <Component
      {...(asCard && { size: 'large' })}
      alignItems="center"
      alignSelf="center"
      padding={padding}
      spacing="s3"
      textAlign="center"
    >
      <Sticker sticker="x-large/push-authentication" />
      <Column spacing="s1">
        {renderTitle({ deviceName })}
        <Body subdued>{t('auth.secondFactorValidation.body')}</Body>
      </Column>
      <Row alignItems="start" spacing="s1_5">
        <Loader noPadding />
        <Body subdued>{t('auth.secondFactorValidation.awaiting', { deviceName })}</Body>
      </Row>
    </Component>
  );
};

export default SecondFactorValidation;
