import { createContext, useRef } from 'react';
import type { Agent } from '@fingerprintjs/fingerprintjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import type { ReactNode } from 'react';
import { useMount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';

import { useFingerprint } from 'app/auth/hooks';

type FingerprintContextType = React.MutableRefObject<Agent | undefined>;

type Props = {
  children: ReactNode;
};

const FingerprintContext = createContext<FingerprintContextType | undefined>(undefined);

// Based on basic tests, computing the fingerprinting was taking
// between 150ms and 250ms most of the time.
// But since this happens once per device, waiting up to 1s one time
// and prevent email when using incognito mode seems a fair tradeoff.
export const FINGERPRINT_TIMEOUT = 1000;

export const FingerprintProvider = ({ children }: Props) => {
  const agentRef = useRef<Agent | undefined>();

  const [fingerprintId, setFingerprintId] = useFingerprint();

  useMount(() => {
    if (!fingerprintId) {
      // ⚠️ The lib does collect usage statistics by default.
      // Don't forget to turn off monitoring!
      // 📜 https://github.com/fingerprintjs/fingerprintjs/blob/master/docs/api.md
      const fingerprintPromise = FingerprintJS.load({ monitoring: false })
        .then((agent) => {
          agentRef.current = agent;
          return agent;
        })
        .then((agent) => agent.get())
        .then(({ visitorId }) => visitorId);

      const fallbackPromise: Promise<string> = new Promise((resolve, _reject) => {
        setTimeout(resolve, FINGERPRINT_TIMEOUT, uuidv4());
      });

      Promise.race([fingerprintPromise, fallbackPromise]).then((id) => setFingerprintId(id));
    }
  });

  if (!fingerprintId) {
    return null;
  }

  return <FingerprintContext.Provider value={agentRef}>{children}</FingerprintContext.Provider>;
};
