import {
  shallowEqual,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux';
import type { Dispatch } from 'redux';

import type { State } from '.';

type Selector<T> = (state: State) => T;

export const useDispatch = (): Dispatch => useDispatchBase();

export const useSelector = <T>(selector: Selector<T>, equalityFn = shallowEqual): T =>
  useSelectorBase(selector, equalityFn);
