import sortBy from 'lodash/sortBy';

import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { useIntl } from '@margobank/components/intl';

import { useLoadPendingGrantingFiles } from 'app/grantings/queries';
import { useLoadCustomers } from 'app/legacy-customer/queries';

import { CustomersMenuOverline } from '../_styled';
import CustomerItem from './CustomerItem';
import GrantingItem from './GrantingItem';

const WORKSPACE_IS_CUSTOMER = 'is-customer' as const;
const WORKSPACE_IS_GRANTING = 'is-granting' as const;

type Props = {
  currentWorkspace?: AuthenticatedCustomerDTO | GrantingFileDTO;
};

const CustomersMenuContent = ({ currentWorkspace }: Props) => {
  const { t } = useIntl();

  const [customers] = useLoadCustomers();
  const [grantingFiles] = useLoadPendingGrantingFiles();

  const customersToWorkspaces = (customers || []).map(({ hash, id, name }) => ({
    id,
    hash,
    name,
    type: WORKSPACE_IS_CUSTOMER,
  }));

  const grantingsToWorkspaces = (grantingFiles || []).map(({ hash, id, name }) => ({
    id,
    hash,
    name,
    type: WORKSPACE_IS_GRANTING,
  }));

  const workspaces = sortBy(
    [...customersToWorkspaces, ...grantingsToWorkspaces],
    ({ name }) => name,
  );

  return (
    <>
      <CustomersMenuOverline>
        {t('customer.userDropdown.companySwitch.title')}
      </CustomersMenuOverline>
      {workspaces.map(({ hash, id, name, type }) =>
        type === WORKSPACE_IS_CUSTOMER ? (
          <CustomerItem
            key={`${type}-${id}`}
            hash={hash}
            isCurrent={id === currentWorkspace?.id}
            name={name}
          />
        ) : type === WORKSPACE_IS_GRANTING ? (
          <GrantingItem
            key={`${type}-${id}`}
            hash={hash}
            isCurrent={id === currentWorkspace?.id}
            name={name}
          />
        ) : null,
      )}
    </>
  );
};

export default CustomersMenuContent;
