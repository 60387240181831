import type { ComponentProps } from 'react';

import { handleFormError } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';

import PhoneNumberConfirmationForm from 'app/auth/_parts/PhoneNumberConfirmationForm';
import { confirmPhoneNumberVerification } from 'app/auth/actions';
import { useRequestPhoneNumberVerification } from 'app/auth/queries';
import type { ConfirmPhoneNumberVerificationRequestDTO } from 'app/auth/types';
import { useDispatch } from 'common/store';

import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onConfirm: () => void;
};

const ConfirmPhoneNumber = ({ onConfirm, ...rest }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();

  const [obfuscatedPhoneNumber, { LoadingState, refetch: resendSMS }] =
    useRequestPhoneNumberVerification({});

  const handleSubmit = (values: ConfirmPhoneNumberVerificationRequestDTO) => {
    return dispatch(confirmPhoneNumberVerification(values)).then(
      (_success) => onConfirm(),
      (error) => handleFormError(error),
    );
  };

  return (
    <PairingLayout
      {...rest}
      subtitle={t('auth.pairing.confirmPhoneNumber.subtitle')}
      title={t('auth.pairing.title')}
    >
      {!obfuscatedPhoneNumber ? (
        <Column alignItems="center">
          <LoadingState />
        </Column>
      ) : (
        <PhoneNumberConfirmationForm
          onResendSMS={() => resendSMS()}
          onSubmit={handleSubmit}
          phoneNumber={obfuscatedPhoneNumber}
        />
      )}
    </PairingLayout>
  );
};

export default ConfirmPhoneNumber;
