import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { logout } from 'app/auth/actions';
import authReducer from 'app/auth/reducer';
import type { State } from 'common/store';
import { initialState } from 'common/store';

const appReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
});

// This code is how Dan Abramov recommends to reset the state of a Redux store:
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
const rootReducer = (state: State, action: any) => {
  if ([logout.fulfilled.toString(), logout.rejected.toString()].includes(action.type)) {
    // Reset store, but keep previous locale, invitation and authError.
    const { authError } = state.auth;
    state = {
      ...initialState,
      auth: {
        authError,
        user: null,
      },
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
