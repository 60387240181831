import { useIntl } from '@margobank/components/intl';

import PhoneNumberConfirmationForm from 'app/auth/_parts/PhoneNumberConfirmationForm';
import { getUnverifiedPhoneNumber } from 'app/auth/helpers';
import { useRequestPhoneNumberUpdatedVerification } from 'app/auth/mutations';
import type { ConfirmPhoneNumberVerificationRequestDTO, UserDTO } from 'app/auth/types';

import ValidationLayout from '../../_parts/ValidationLayout';

type Props = {
  onPrevious: () => void;
  onSubmit: (values: ConfirmPhoneNumberVerificationRequestDTO) => Promise<void>;
  user: UserDTO;
};

const PhoneNumberSMSValidation = ({ onSubmit, user, ...rest }: Props) => {
  const { t } = useIntl();
  const [requestPhoneNumberUpdatedVerification] = useRequestPhoneNumberUpdatedVerification();

  const unverifiedPhoneNumber = getUnverifiedPhoneNumber(user);

  if (!unverifiedPhoneNumber) {
    throw Error('unverifiedPhoneNumber must be defined');
  }

  const handleResendSMS = () => requestPhoneNumberUpdatedVerification({ userId: user.id });

  return (
    <ValidationLayout
      subtitle={t('auth.validation.phoneNumber.subtitle.confirmSMS')}
      title={t('auth.validation.phoneNumber.title')}
      {...rest}
    >
      <PhoneNumberConfirmationForm
        onResendSMS={handleResendSMS}
        onSubmit={onSubmit}
        phoneNumber={unverifiedPhoneNumber}
        title={t('auth.validation.phoneNumber.confirmSMS.title')}
      />
    </ValidationLayout>
  );
};

export default PhoneNumberSMSValidation;
