import { getErrorStatus, HTTP_UNAUTHORIZED } from '@margobank/components/common/error';

import type { ActionRejected, ActionType } from 'common/store';

import {
  clearDeviceReplacementVerificationToken,
  clearPhoneNumberVerificationToken,
  confirmPhoneNumberVerification,
  keepAlive,
  loadPairingQRcode,
  loadUser,
  login,
  logout,
  requestDeviceReplacement,
  setupPassword,
} from './actions';
import { AUTH_ERROR } from './constants';
import type { ApprovalRequestDTO, AuthError, PairingQRCodeDTO, UserDTO } from './types';

export type AuthState = {
  approvalRequests?: {
    [requestId: string]: ApprovalRequestDTO;
  };
  authError?: AuthError;
  deviceReplacementVerificationToken?: string;
  pairingQRCode?: PairingQRCodeDTO;
  phoneNumberVerificationToken?: string;
  sessionToken?: string;
  // `user` will be `null` after a logout or a session expiration
  user?: UserDTO | null;
};

const authReducer = (state = {} as AuthState, action): AuthState => {
  switch (action.type) {
    case clearDeviceReplacementVerificationToken.toString(): {
      return {
        ...state,
        deviceReplacementVerificationToken: undefined,
      };
    }

    case clearPhoneNumberVerificationToken.toString(): {
      return {
        ...state,
        phoneNumberVerificationToken: undefined,
      };
    }

    case confirmPhoneNumberVerification.fulfilled.toString(): {
      const {
        payload: { verificationToken: phoneNumberVerificationToken },
      } = action as ActionType<typeof confirmPhoneNumberVerification>;
      return {
        ...state,
        phoneNumberVerificationToken,
      };
    }

    case keepAlive.rejected.toString(): {
      const { payload: error } = action as ActionRejected;
      if (getErrorStatus(error) === HTTP_UNAUTHORIZED) {
        return {
          authError: AUTH_ERROR.SESSION_EXPIRED,
          user: null,
        };
      }
      return state;
    }

    case login.pending.toString(): {
      return {
        ...state,
        authError: undefined,
      };
    }

    case login.fulfilled.toString():
    case setupPassword.fulfilled.toString(): {
      const {
        payload: {
          session: { token },
          user,
        },
      } = action as ActionType<typeof login | typeof setupPassword>;

      return {
        ...state,
        sessionToken: token,
        user,
      };
    }

    case loadPairingQRcode.fulfilled.toString(): {
      const { payload: pairingQRCode } = action as ActionType<typeof loadPairingQRcode>;
      return { ...state, pairingQRCode };
    }

    case loadUser.fulfilled.toString(): {
      const { payload: user } = action as ActionType<typeof loadUser>;
      return { ...state, user };
    }

    case loadUser.rejected.toString(): {
      const { payload: error } = action as ActionRejected;
      if (getErrorStatus(error) === HTTP_UNAUTHORIZED) {
        return {};
      }
      return state;
    }

    case logout.fulfilled.toString():
    case logout.rejected.toString(): {
      const { authError: previousAuthError } = state;
      const {
        meta: { authError },
      } = action as ActionType<typeof logout>;
      return {
        authError: authError || previousAuthError,
        user: null,
      };
    }

    case requestDeviceReplacement.fulfilled.toString(): {
      const {
        payload: { verificationToken: deviceReplacementVerificationToken },
      } = action as ActionType<typeof requestDeviceReplacement>;
      return {
        ...state,
        deviceReplacementVerificationToken,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
