import { IconRow, Row } from '@margobank/components/layout';
import { styled } from '@memobank/style';

export const StyledIconRow = styled(IconRow)`
  min-width: 0;
`;

export const StyledUserThumbnail = styled(Row)`
  margin-top: -${({ theme }) => theme.spacing.s0};
  margin-bottom: -${({ theme }) => theme.spacing.s0};
`;
