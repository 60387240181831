import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { Route as RouteBase, useRouteMatch } from 'react-router-dom';

import { useRouter } from './hooks';
import type { RouteProps } from './types';

/**
 * We use a custom Route component to be able to read route params outside a route context.
 * When `shouldCanonicalizeParams` is truthy, params are stored in a global context, defined in a custom `Router` component.
 */
const Route = ({
  component: Component,
  description,
  render,
  shouldCanonicalizeParams = true,
  title,
  ...props
}: RouteProps) => {
  return (
    <>
      {(title || description) && (
        <Helmet>
          {title && <title>{title}</title>}
          {description && <meta content={description} name="description" />}
        </Helmet>
      )}
      <RouteBase
        {...props}
        render={(routerProps) => (
          <TrackedRoute shouldCanonicalizeParams={shouldCanonicalizeParams}>
            {render ? (
              render(routerProps)
            ) : Component ? (
              <Component {...routerProps} {...props} />
            ) : null}
          </TrackedRoute>
        )}
      />
    </>
  );
};

type TrackedRouteProps = RouteProps & {
  children: ReactNode;
};

const TrackedRoute = ({ children, shouldCanonicalizeParams }: TrackedRouteProps) => {
  const { params, url } = useRouteMatch();
  const { setParamsToCanonicalize } = useRouter();

  useEffect(
    () => {
      if (shouldCanonicalizeParams) {
        setParamsToCanonicalize((prev) => ({ ...prev, ...params }));

        return () => {
          setParamsToCanonicalize((prev) => {
            const value = { ...prev };
            Object.keys(params).forEach((param) => {
              delete value[param];
            });
            return value;
          });
        };
      }
    },
    // We use `url` as dependency instead of `params`, because `params` changes with every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldCanonicalizeParams, setParamsToCanonicalize, url],
  );

  return <>{children}</>;
};

export default Route;
