import { DropdownMenuContent } from '@margobank/components/dropdown/DropdownMenu/_styled';
import { IconRow } from '@margobank/components/layout';
import { Overline } from '@margobank/components/text';
import { styled } from '@memobank/style';

export const CustomersMenuDropdownWrapper = styled.div`
  ${DropdownMenuContent} {
    margin: ${({ theme }) => `-${theme.spacing.s0} -${theme.spacing.s0} 0 0`};
  }
`;

export const CustomersMenuIconRow = styled(IconRow).attrs({
  alignItems: 'center',
  flex: 1,
  justifyContent: 'space-between',
  spacing: 's1',
})``;

export const CustomersMenuOverline = styled(Overline).attrs({ subdued: true })`
  padding: ${({ theme }) => `${theme.spacing.s1} ${theme.spacing.s2}`};
`;
