import { useIntl } from '@margobank/components/intl';
import { Row } from '@margobank/components/layout';
import Loader from '@margobank/components/Loader';
import { Body } from '@margobank/components/text';
import { Sticker } from '@memobank/ui';

import SecondFactorValidationLayout from './SecondFactorValidationLayout';

type Props = {
  deviceName: string;
  title: string;
};

const SecondFactorValidationPending = ({ deviceName, title }: Props) => {
  const { t } = useIntl();

  return (
    <SecondFactorValidationLayout
      body={t('auth.secondFactorValidation.body')}
      footerElement={
        <Row alignItems="start" spacing="s1_5">
          <Loader noDelay noPadding />
          <Body subdued>{t('auth.secondFactorValidation.awaiting', { deviceName })}</Body>
        </Row>
      }
      stickerElement={<Sticker sticker="x-large/push-authentication" />}
      title={title}
    />
  );
};

export default SecondFactorValidationPending;
