import React from 'react';

import { useIntl } from '@margobank/components/intl';

import googlePlayStoreEN from 'assets/images/google-play-store-en.png';
import googlePlayStoreFR from 'assets/images/google-play-store-fr.png';

const GooglePlayStoreButton = () => {
  const { locale } = useIntl();

  const appIconForLocale = (locale) => {
    switch (locale) {
      case 'fr':
        return googlePlayStoreFR;
      case 'en':
        return googlePlayStoreEN;
      default:
        throw Error(`Unsupported store icon for language ${locale}`);
    }
  };

  return (
    <a
      href="https://play.google.com/store/apps/details?id=bank.memo.android"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="Google Play Store" height="40" src={appIconForLocale(locale)} />
    </a>
  );
};

export default GooglePlayStoreButton;
