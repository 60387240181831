import type { ReactNode } from 'react';

import { InlineLink } from '@margobank/components/text';

import { useHelpBeacon } from '.';

type Props = {
  children: ReactNode;
};

const HelpMessageLinkButton = ({ children }: Props) => {
  const { openMessageBox } = useHelpBeacon();

  return <InlineLink onClick={openMessageBox}>{children}</InlineLink>;
};

export default HelpMessageLinkButton;
