import type { ComponentProps } from 'react';

import { PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { Body, Subheading } from '@margobank/components/text';

import { useLoadPairedDeviceInformation } from 'app/auth/queries';
import pairedPhone from 'assets/images/paired-phone.svg';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = {
  closeButton?: ComponentProps<typeof PairingLayout>['closeButton'];
  onConfirm: () => void;
  userId: string;
};

const Validation = ({ closeButton, onConfirm, userId }: Props) => {
  const [deviceInformation, { LoadingState }] = useLoadPairedDeviceInformation({ userId });
  const { t } = useIntl();

  if (!deviceInformation) {
    return <LoadingState />;
  }

  const { name: deviceName } = deviceInformation;

  return (
    <PairingLayout
      subtitle={t('auth.pairing.validation.subtitle')}
      title={t('auth.pairing.title')}
      {...(closeButton && { ...closeButton, label: t('common.actions.close') })}
    >
      <PairingCard>
        <img
          alt={t('auth.pairing.validation.intro', { deviceName })}
          height="140"
          src={pairedPhone}
          width="72"
        />
        <Column spacing="s1" textAlign="center">
          <Subheading>{t('auth.pairing.validation.intro', { deviceName })}</Subheading>
          <Body subdued>{t('auth.pairing.validation.body')}</Body>
        </Column>
        <PrimaryButton onClick={onConfirm}>{t('auth.pairing.validation.success')}</PrimaryButton>
      </PairingCard>
    </PairingLayout>
  );
};

export default Validation;
