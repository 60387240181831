import type { PaginatedList } from '@margobank/components/common/types';
import type {
  CardRequestDTO,
  CardRequestLiteDTO,
  CardShippingDTO,
  LoadCardsRequestsParams,
} from '@margobank/components/domain/cards';
import { createQueryHook, extendQueryHook } from '@margobank/components/query-helpers';
import { canonicalize } from '@memobank/utils';

import http, { CUSTOMERS_SERVICE_PREFIX } from 'common/http';

import type { CardRequestValidationRuleDTO } from './types';

// MARK: Cards renewal and shipping

const LOAD_CARD_SHIPPING = 'LOAD_CARD_SHIPPING';

type UseLoadCardShipping = {
  cardId: string;
  customerId: string;
};

export const useLoadCardShipping = createQueryHook(
  LOAD_CARD_SHIPPING,
  async ({ cardId, customerId }: UseLoadCardShipping) => {
    const { data } = await http.get<CardShippingDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}/shipping`,
    );
    return data;
  },
);

// MARK: Validation rules queries

const LOAD_CARDS_VALIDATION_RULES = 'LOAD_CARDS_VALIDATION_RULES';

type UseLoadCardsValidationRules = {
  customerId: string;
};

export const useLoadCardsValidationRules = createQueryHook(
  LOAD_CARDS_VALIDATION_RULES,
  async ({ customerId }: UseLoadCardsValidationRules) => {
    const { data } = await http.get<CardRequestValidationRuleDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards_request_validation_rules`,
    );
    return data;
  },
);

// MARK: Cards requests queries

const LOAD_CARD_REQUEST = 'LOAD_CARD_REQUEST';
const LOAD_CARDS_REQUESTS = 'LOAD_CARDS_REQUESTS';

type UseLoadCardsRequest = {
  cardRequestId: string;
  customerId: string;
  userId?: string;
};

export const useLoadCardRequest = createQueryHook(
  LOAD_CARD_REQUEST,
  async ({ cardRequestId, customerId, userId }: UseLoadCardsRequest) => {
    const { data } = await http.get<CardRequestDTO>(
      userId
        ? `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/users/${userId}/cards_request/${cardRequestId}`
        : `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards_request/${cardRequestId}`,
    );
    return data;
  },
);

type UseLoadCardsRequests = {
  customerId: string;
  params?: LoadCardsRequestsParams;
  userId?: string;
};

export const useLoadCardsRequests = createQueryHook(
  LOAD_CARDS_REQUESTS,
  async ({ customerId, params, userId }: UseLoadCardsRequests) => {
    const { data } = await http.get<PaginatedList<CardRequestLiteDTO>>(
      userId
        ? `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/users/${userId}/cards_request`
        : `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards_request`,
      { params: canonicalize(params) },
    );
    return data;
  },
);

export const useLoadCardsRequestsCount = extendQueryHook(useLoadCardsRequests, {
  select: ({ totalElements }) => totalElements,
});
