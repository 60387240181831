import axios from 'axios';
import queryString from 'query-string';

import type { FullSearchPaginatedWrapper, PaginatedList } from '@margobank/components/common/types';
import type {
  CardDetailsDTO,
  CardDTO,
  CardShippingDTO,
  CardsInformationDTO,
  ExportCardOperationsParams,
  LoadCardOperationsParams,
  LoadCardsParams,
} from '@margobank/components/domain/cards';
import type {
  CardOperationDetailsDTO,
  CardOperationDTO,
  OperationsExportType,
} from '@margobank/components/domain/operations';
import type { UserLiteDTO } from '@margobank/components/domain/user';
import {
  createFileActionsHook,
  createInfiniteQueryHook,
  createQueryHook,
} from '@margobank/components/query-helpers';
import { canonicalize } from '@memobank/utils';

import { hasPendingOCR } from 'app/legacy-customer/helpers';
import { useCustomerStore } from 'app/legacy-customer/hooks';
import http, { CUSTOMERS_SERVICE_PREFIX } from 'common/http';

import { parseEnfuceHTML } from './helpers';

const PENDING_OCR_REFRESH_DELAY_MS = 1_000;

const LOAD_CUSTOMER_CARD = 'LOAD_CUSTOMER_CARD';
const LOAD_CUSTOMER_CARD_OPERATION = 'LOAD_CUSTOMER_CARD_OPERATION';
const LOAD_CUSTOMER_CARD_OPERATIONS = 'LOAD_CUSTOMER_CARD_OPERATIONS';
const LOAD_CUSTOMER_CARD_OPERATIONS_EXPORT_TYPE = 'LOAD_CUSTOMER_CARD_OPERATIONS_EXPORT_TYPE';
const LOAD_CUSTOMER_CARD_PAN = 'LOAD_CUSTOMER_CARD_PAN';
const LOAD_CUSTOMER_CARD_SHIPPING_RENEWAL = 'LOAD_CUSTOMER_CARD_SHIPPING_RENEWAL';
const LOAD_CUSTOMER_CARDS = 'LOAD_CUSTOMER_CARDS';
const LOAD_CUSTOMER_CARDS_HOLDERS = 'LOAD_CUSTOMER_CARDS_HOLDERS';
const LOAD_CUSTOMER_CARDS_INFORMATION = 'LOAD_CUSTOMER_CARDS_INFORMATION';

// MARK: - Queries

export const getCustomerCardOperationsExportUrl = (
  customerId: string,
  exportParams: ExportCardOperationsParams,
) =>
  `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/export?${queryString.stringify(
    exportParams,
  )}`;

type UseLoadCustomerCard = {
  cardId: string;
  customerId: string;
};

export const useLoadCustomerCard = createQueryHook(
  LOAD_CUSTOMER_CARD,
  async ({ cardId, customerId }: UseLoadCustomerCard) => {
    const { data } = await http.get<CardDetailsDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}`,
    );
    return data;
  },
);

type UseLoadCustomerCardOperation = {
  cardId: string;
  cardOperationId: string;
  customerId: string;
};

export const useLoadCustomerCardOperation = createQueryHook(
  LOAD_CUSTOMER_CARD_OPERATION,
  async ({ cardId, cardOperationId, customerId }: UseLoadCustomerCardOperation) => {
    const { data } = await http.get<CardOperationDetailsDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}/operations/${cardOperationId}`,
    );
    return data;
  },
  {
    refetchInterval: (data) => (hasPendingOCR(data) ? PENDING_OCR_REFRESH_DELAY_MS : false),
  },
);

type UseLoadCustomerCardOperations = {
  customerId: string;
  page?: number;
  searchParams?: LoadCardOperationsParams;
};

export const useLoadCustomerCardOperations = createInfiniteQueryHook(
  LOAD_CUSTOMER_CARD_OPERATIONS,
  async ({ customerId, page = 1, searchParams }: UseLoadCustomerCardOperations) => {
    const {
      data: { isFullSearch, wrapper },
    } = await http.get<FullSearchPaginatedWrapper<CardOperationDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/operations`,
      { params: canonicalize({ page, ...searchParams, includeExpired: true }) },
    );

    useCustomerStore.setState({
      isFullSearchDisabled: isFullSearch === false,
    });

    return wrapper;
  },
);

type UseLoadCustomerCardOperationsExportType = {
  customerId: string;
  exportParams?: ExportCardOperationsParams;
};

export const useLoadCustomerCardOperationsExportType = createQueryHook(
  LOAD_CUSTOMER_CARD_OPERATIONS_EXPORT_TYPE,
  async ({ customerId, exportParams }: UseLoadCustomerCardOperationsExportType) => {
    const { data } = await http.get<OperationsExportType>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/export_type`,
      { params: canonicalize(exportParams) },
    );
    return data;
  },
);

// Should be called after "useLoadCustomerCardSecuredLink" to get the token and url.
type UseLoadCustomerCardPan = {
  token: string;
  url: string;
};

export const useLoadCustomerCardPan = createQueryHook(
  LOAD_CUSTOMER_CARD_PAN,
  async ({ token, url }: UseLoadCustomerCardPan) => {
    const { data } = await axios.get<string>(url, { params: { token } });
    return parseEnfuceHTML(data);
  },
  { cacheTime: 0 },
);

type UseLoadCustomerCardShippingRenewal = {
  cardId: string;
  customerId: string;
};

export const useLoadCustomerCardShippingRenewal = createQueryHook(
  LOAD_CUSTOMER_CARD_SHIPPING_RENEWAL,
  async ({ cardId, customerId }: UseLoadCustomerCardShippingRenewal) => {
    const { data } = await http.get<CardShippingDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}/shipping/renewal`,
    );
    return data;
  },
);

type UseLoadCustomerCards = {
  customerId: string;
  params?: LoadCardsParams;
};

export const useLoadCustomerCards = createQueryHook(
  LOAD_CUSTOMER_CARDS,
  async ({ customerId, params }: UseLoadCustomerCards) => {
    const { data } = await http.get<PaginatedList<CardDTO>>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards`,
      { params: canonicalize(params) },
    );
    return data;
  },
);

type UseLoadCustomerCardsHolders = {
  customerId: string;
};

export const useLoadCustomerCardsHolders = createQueryHook(
  LOAD_CUSTOMER_CARDS_HOLDERS,
  async ({ customerId }: UseLoadCustomerCardsHolders) => {
    const { data } = await http.get<UserLiteDTO[]>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/holders`,
    );
    return data;
  },
);

type UseLoadCustomerCardsInformation = {
  customerId: string;
};

export const useLoadCustomerCardsInformation = createQueryHook(
  LOAD_CUSTOMER_CARDS_INFORMATION,
  async ({ customerId }: UseLoadCustomerCardsInformation) => {
    const { data } = await http.get<CardsInformationDTO>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/information`,
    );
    return data;
  },
);

// MARK: - File Actions

type UseCardOperationDocumentFileActions = {
  cardId: string;
  cardOperationId: string;
  customerId: string;
  documentId: string;
};

export const useCardOperationDocumentFileActions = createFileActionsHook(
  async ({
    cardId,
    cardOperationId,
    customerId,
    documentId,
  }: UseCardOperationDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      `${CUSTOMERS_SERVICE_PREFIX}/customers/${customerId}/cards/${cardId}/operations/${cardOperationId}/documents/${documentId}`,
      { responseType: 'blob' },
    );
    return data;
  },
);
