import React from 'react';

import { useIntl } from '@margobank/components/intl';

import appleAppStoreEN from 'assets/images/app-store-badge-en.svg';
import appleAppStoreFR from 'assets/images/app-store-badge-fr.svg';

const AppleAppStoreButton = () => {
  const { locale } = useIntl();

  const appIconForLocale = (locale) => {
    switch (locale) {
      case 'fr':
        return appleAppStoreFR;
      case 'en':
        return appleAppStoreEN;
      default:
        throw Error(`Unsupported store icon for language ${locale}`);
    }
  };

  return (
    <a
      href="https://apps.apple.com/fr/app/memo-bank-authenticator/id1500599670"
      rel="noopener noreferrer"
      target="_blank"
    >
      <img alt="Apple App Store" height="40" src={appIconForLocale(locale)} />
    </a>
  );
};

export default AppleAppStoreButton;
