import { create as createStore } from 'zustand';

type CustomerStore = {
  isFullSearchDisabled: boolean;
};

const useCustomerStore = createStore<CustomerStore>()(() => ({
  isFullSearchDisabled: false,
}));

export const useIsFullSearchDisabled = () => {
  return useCustomerStore(({ isFullSearchDisabled }) => isFullSearchDisabled);
};

export default useCustomerStore;
