import type { ReactNode } from 'react';

import { LinkButton } from '@margobank/components/Button';
import { useDevice } from '@margobank/components/device';
import { useIntl } from '@margobank/components/intl';
import { Column, Row, Spreader } from '@margobank/components/layout';
import {
  Header,
  HeaderButton,
  StickyHeader,
  StyledStickerIcon,
} from '@margobank/components/Modal/full-page/_styled';
import { Body, Heading, Subheading } from '@margobank/components/text';
import { Icon } from '@memobank/ui';

import UserDropdown from 'components/UserDropdown';

import { FullSizeHeader, PaddingWrapper } from './_styled';

type Props = {
  children: ReactNode;
  onPrevious?: () => void;
  subtitle?: string;
  title: string;
};

const Layout = ({ children, onPrevious, subtitle, title }: Props) => {
  const { t } = useIntl();
  const { isScreenAtLeastL } = useDevice();

  const renderBackButton = () => (
    <Row flex="1" justifyContent="flex-start">
      <HeaderButton onClick={onPrevious} title={t('common.back')}>
        <StyledStickerIcon icon="medium/chevron_left" />
        <Body bold hideFor={{ max: 'l' }}>
          {t('common.back')}
        </Body>
      </HeaderButton>
    </Row>
  );

  const renderTitle = () => (
    <Header spacing="s0_5" textAlign="center">
      <Heading>{title}</Heading>
      <Subheading highlighted>{subtitle || ' '}</Subheading>
    </Header>
  );

  const renderFullSizeHeader = () => (
    <FullSizeHeader>
      {onPrevious ? renderBackButton() : <Spreader />}
      {renderTitle()}
      <Row flex="1" justifyContent="flex-end">
        <UserDropdown />
      </Row>
    </FullSizeHeader>
  );

  const renderSmallSizeHeader = () => (
    <>
      <StickyHeader offset="0">
        {onPrevious ? (
          <LinkButton
            iconPrefixElement={<Icon icon="medium/chevron_right_small" rotate="180deg" subdued />}
            onClick={onPrevious}
            subdued
            withoutSpacing
          >
            {t('common.back')}
          </LinkButton>
        ) : (
          <Spreader />
        )}
        <UserDropdown onlyLogout />
      </StickyHeader>
      {renderTitle()}
    </>
  );

  return (
    <Row>
      <PaddingWrapper spacing={isScreenAtLeastL ? 's6' : undefined}>
        {isScreenAtLeastL ? renderFullSizeHeader() : renderSmallSizeHeader()}
        <Column maxWidth="medium" textAlign="center">
          {children}
        </Column>
      </PaddingWrapper>
    </Row>
  );
};

export default Layout;
