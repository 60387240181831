import type { ComponentProps } from 'react';

import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column, IconRow, Row } from '@margobank/components/layout';
import Loader from '@margobank/components/Loader';
import { Body, STATUS, Subheading } from '@margobank/components/text';
import { Icon } from '@memobank/ui';

import { useLoadUnpairedDeviceInformation } from 'app/auth/queries';
import { useHelpBeacon } from 'components/help-beacon';
import { AppleAppStoreButton, GooglePlayStoreButton } from 'components/mobile-app-stores-buttons';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';
import { SubduedSpan } from './_styled';

const REFETCH_INTERVAL_DELAY_MS = 1_000;

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onConfirm: () => void;
  userId: string;
};

const Installation = ({ onConfirm, userId, ...rest }: Props) => {
  const { t } = useIntl();
  const { showHelpArticle } = useHelpBeacon();

  const [deviceInformation] = useLoadUnpairedDeviceInformation(
    { userId },
    {
      refetchInterval: (device) => (device ? false : REFETCH_INTERVAL_DELAY_MS),
    },
  );

  return (
    <PairingLayout
      {...rest}
      subtitle={t('auth.pairing.installation.subtitle')}
      title={t('auth.pairing.title')}
    >
      <Column spacing="s3">
        <PairingCard>
          <Column spacing="s1">
            <Subheading>
              <SubduedSpan>1.</SubduedSpan> {t('auth.pairing.installation.intro')}
            </Subheading>
            <Body subdued>{t('auth.pairing.installation.body')}</Body>
          </Column>
          <Row spacing="s2">
            <AppleAppStoreButton />
            <GooglePlayStoreButton />
          </Row>
        </PairingCard>
        <PairingCard>
          <Subheading>
            <SubduedSpan>2.</SubduedSpan> {t('auth.pairing.installation.detection.intro')}
          </Subheading>
          {!deviceInformation ? (
            <Loader message={t('auth.pairing.installation.detection.loading')} />
          ) : (
            <>
              <IconRow status={STATUS.SUCCESS}>
                <Icon icon="medium/check_circled" />
                <Body>
                  {t('auth.pairing.installation.detection.success', {
                    deviceName: deviceInformation.name,
                  })}
                </Body>
              </IconRow>
              <PrimaryButton onClick={onConfirm}>
                {t('auth.pairing.installation.confirm')}
              </PrimaryButton>
            </>
          )}
        </PairingCard>
        <LinkButton
          iconPrefix="medium/info"
          onClick={() => showHelpArticle('PAIRING_INSTALLATION')}
        >
          {t('auth.pairing.installation.help')}
        </LinkButton>
      </Column>
    </PairingLayout>
  );
};

export default Installation;
