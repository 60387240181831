import { useCallback, useRef } from 'react';
import type { QueryClient, UseMutateAsyncFunction, UseMutationOptions } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import { getErrorMessage } from '@margobank/components/common/error';
import type { ErrorResponse } from '@margobank/components/common/types';

import { getApprovalRequestPartsFromError } from 'app/auth/helpers';
import { X_APPROVAL_REQUEST_ID } from 'common/http';
import { useSecondFactorValidation } from 'components/SecondFactorValidation';
import type { SecondFactorValidationOptions } from 'components/SecondFactorValidation/SecondFactorValidationProvider';

/**
 * This helper is based on the `createMutationHook` helper from '@margobank/components/query-helpers'.
 *
 * If the mutation fails because it requires an approval request, it will show the
 * `SecondFactorValidation` component in a modal, and will retry the mutation sending the proper
 * headers once the approval request has been approved.
 *
 * @example
 *
 * // Hook creation
 *
 * type UseFakeActionWith2FAParams = {
 *   headers?: Record<string, string>;
 *   someParams: any;
 * };
 *
 * const useFakeActionWith2FA = createMutationHookWith2FA(
 *   async ({ headers, someParams }: UseFakeActionWith2FAParams) => {
 *     const { data } = await http.post(
 *       '/some-backend-enpoint-requiring-2fa',
 *       someParams,
 *       { headers }
 *     );
 *     return data;
 *   }
 * );
 *
 *
 * // Hook usage
 *
 * const MyComponent = () => {
 *   const [fakeActionWith2FA] = useFakeActionWith2FA({
 *     getTitle: ({ deviceName }) => `Approve action on ${deviceName}`
 *   });
 *
 *   const handleSubmit = (someParams) => {
 *     return fakeActionWith2FA({ someParams }).catch(error => handleFormError(error));
 *   };
 *
 *   return (
 *     <Form onSumbmit={handleSubmit}>
 *       {(form) => (
 *         <ActionBar error={form.error}>
 *           <PrimaryButton type="submit">Submit</PrimaryButton>
 *         </ActionBar>
 *       )}
 *     </Form>
 *   );
 * };
 */

type UseMutationOptionsWithQueryClient<TData, TError, TVariables, TContext> = Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'onSuccess'
> & {
  onSuccess?: (
    data: TData,
    variables: TVariables,
    context: TContext | undefined,
    queryClient: QueryClient,
  ) => Promise<unknown> | void;
};

const createMutationHookWith2FA =
  <
    TData,
    TVariables extends { headers?: Record<string, string> },
    TError = ErrorResponse,
    TContext = unknown,
  >(
    mutationFn: (args: TVariables & { headers: Record<string, string> }) => Promise<TData>,
    defaultOptions?: UseMutationOptionsWithQueryClient<TData, TError, TVariables, TContext>,
  ) =>
  (
    secondFactorValidationOptions?: SecondFactorValidationOptions,
    options?: UseMutationOptions<TData, TError, TVariables, TContext>,
  ) => {
    const queryClient = useQueryClient();

    const [waitForSecondFactorValidation, secondFactorValidationMeta] = useSecondFactorValidation(
      secondFactorValidationOptions,
    );

    /**
     * We use a Ref to store this boolean instead of a state, to ensure we always read the up-to-date value,
     * even in the context of the function that itself calls the mutation.
     */
    const hasTriggeredSecondFactorValidationRef = useRef(false);

    const getHasTriggeredSecondFactorValidation = useCallback(
      () => hasTriggeredSecondFactorValidationRef.current,
      [],
    );

    const mutationHelpers = useMutation<TData, TError, TVariables, TContext>(mutationFn, {
      ...defaultOptions,
      onSuccess: (...args) =>
        defaultOptions?.onSuccess ? defaultOptions.onSuccess(...args, queryClient) : undefined,
      ...options,
    });

    const errorMessage = mutationHelpers.error ? getErrorMessage(mutationHelpers.error) : undefined;

    const tryMutate = async (args: TVariables) => {
      hasTriggeredSecondFactorValidationRef.current = false;

      // Try the mutation without 2FA headers
      try {
        return await mutationHelpers.mutateAsync(args);
      } catch (error) {
        // Run 2FA flow if 2FA headers are present in the error response
        const { id: approvalRequestId, token } = getApprovalRequestPartsFromError(error);
        if (approvalRequestId) {
          hasTriggeredSecondFactorValidationRef.current = true;

          // Wait for aproval request validation
          return waitForSecondFactorValidation({ approvalRequestId, token }, () => {
            // Retry the mutation with 2FA headers
            const headers = { [X_APPROVAL_REQUEST_ID]: approvalRequestId };
            return mutationHelpers.mutateAsync({ ...args, headers });
          });
        } else {
          throw error;
        }
      }
    };

    return [
      tryMutate as UseMutateAsyncFunction<TData, TError, TVariables>,
      {
        ...mutationHelpers,
        ...secondFactorValidationMeta,
        errorMessage,
        getHasTriggeredSecondFactorValidation,
      },
    ] as const;
  };

export default createMutationHookWith2FA;
