import { useHistory } from 'react-router-dom';

import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { DropdownMenu } from '@margobank/components/dropdown';
import { useIntl } from '@margobank/components/intl';

import { logout } from 'app/auth/actions';
import { useLoadPendingGrantingFiles } from 'app/grantings/queries';
import { useLoadCustomers } from 'app/legacy-customer/queries';
import { useDispatch } from 'common/store';
import { userSettingsPath } from 'helpers/routes';

import { UserMenuItem } from '../_styled';
import CustomersMenu from '../CustomersMenu';

type Props = {
  onItemClicked?: () => void;
  onLogout?: () => void;
  onlyLogout: boolean;
} & (
  | { customer?: AuthenticatedCustomerDTO }
  | {
      grantingFile?: GrantingFileDTO;
    }
);

const UserMenuContent = ({ onItemClicked, onLogout, onlyLogout, ...rest }: Props) => {
  const { t } = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();

  const [customers] = useLoadCustomers();
  const [grantingFiles] = useLoadPendingGrantingFiles();

  const currentCustomer = 'customer' in rest ? rest.customer : undefined;
  const currentGrantingFile = 'grantingFile' in rest ? rest.grantingFile : undefined;
  const customerHash = currentCustomer?.hash;

  // Don't show the customer switch for users with only one workspace
  const shouldRenderCustomerMenu = (customers || []).length + (grantingFiles || []).length > 1;

  const handleLogout = () => {
    if (typeof onLogout === 'undefined') {
      // Force redirect to / because activation/invitation routes are not PrivateRoute
      dispatch(logout()).then(() => history.push('/'));
    } else {
      onLogout();
    }
  };

  return (
    <>
      {!onlyLogout && (
        <>
          {customerHash && (
            <UserMenuItem onClick={onItemClicked} to={userSettingsPath(customerHash)}>
              {t('customer.userDropdown.userSettings')}
            </UserMenuItem>
          )}
          {shouldRenderCustomerMenu && (
            <CustomersMenu currentWorkspace={currentCustomer || currentGrantingFile} />
          )}
          {(customerHash || shouldRenderCustomerMenu) && <DropdownMenu.Divider />}
        </>
      )}
      <UserMenuItem onClick={handleLogout}>{t('customer.userDropdown.logout')}</UserMenuItem>
    </>
  );
};

export default UserMenuContent;
