import { useDispatch } from 'common/store';
import UserDropdown from 'components/UserDropdown';

import ConfirmConvention from '../_steps/ConfirmConvention';
import UploadIdentityProof from '../_steps/UploadIdentityProof';
import { loadUser } from '../actions';
import Pairing from '../Pairing';
import type { UserDTO } from '../types';
import { ValidationPhoneNumber } from '../Validation';
import ValidationEmail from '../Validation/ValidationEmail';
import useSetupUserFlow from './useSetupUserFlow';

type Props = {
  user: UserDTO;
  withSetupSteps?: boolean;
  withVerificationSteps?: boolean;
};

const SetupUser = ({ user, withSetupSteps, withVerificationSteps }: Props) => {
  const dispatch = useDispatch();

  const { currentScreen, goToNextScreen } = useSetupUserFlow({
    user,
    withSetupSteps,
    withVerificationSteps,
  });

  const onAfterPairing = () => {
    goToNextScreen();
    // Reload the user
    dispatch(loadUser());
  };

  return (
    <>
      {currentScreen === 'UPLOAD_IDENTITY_PROOF' && (
        <UploadIdentityProof onConfirm={goToNextScreen} user={user} />
      )}
      {currentScreen === 'ACCEPT_TERMS' && (
        <ConfirmConvention onConfirm={goToNextScreen} user={user} />
      )}
      {currentScreen === 'PAIR_2FA_DEVICE' && (
        <Pairing
          onAfterPairing={onAfterPairing}
          rightComponent={<UserDropdown variant="light" />}
        />
      )}
      {currentScreen === 'VALIDATION_PHONE' && (
        <ValidationPhoneNumber onSuccess={goToNextScreen} user={user} />
      )}
      {currentScreen === 'VALIDATION_EMAIL' && <ValidationEmail user={user} />}
    </>
  );
};

export default SetupUser;
