import { ErrorAlert } from '@margobank/components/alert';
import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { createForm, DigitsField } from '@margobank/components/form';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { useModalState } from '@margobank/components/Modal';
import { Body, Subheading } from '@margobank/components/text';

import type { ConfirmPhoneNumberVerificationRequestDTO } from 'app/auth/types';

import PairingCard from '../../Pairing/_parts/PairingCard';
import ResendPhoneNumberConfirmationModal from '../ResendPhoneNumberConfirmationModal';
import { DigitsFieldColumn } from './_styled';

const Form = createForm('auth.pairing.phoneValidation');

type Props = {
  onResendSMS: () => Promise<any>;
  onSubmit: (values: ConfirmPhoneNumberVerificationRequestDTO) => Promise<void>;
  phoneNumber: string;
  title?: string;
};

const PhoneNumberConfirmationForm = ({ onResendSMS, onSubmit, phoneNumber, title }: Props) => {
  const { formatPhone, t } = useIntl();
  const [isModalOpen, { closeModal, openModal }] = useModalState();

  return (
    <>
      <Form onSubmit={onSubmit}>
        {(form) => (
          <PairingCard spacing="s4">
            <Column spacing="s2">
              <Column spacing="s1" textAlign="center">
                <Subheading>{title || t('auth.pairing.confirmPhoneNumber.intro')}</Subheading>
                <Body>
                  {t('auth.confirmPhoneNumber.subtitle', {
                    em: (chunk) => (
                      <Body bold highlighted inline>
                        {chunk}
                      </Body>
                    ),
                    phoneNumber: formatPhone(phoneNumber),
                  })}
                </Body>
              </Column>
              <Body>{t('auth.confirmPhoneNumber.instruction')}</Body>
            </Column>
            <DigitsFieldColumn spacing="s3">
              {form.error && <ErrorAlert message={form.error} />}
              <DigitsField autoFocus mask="999-999" name="code" />
            </DigitsFieldColumn>
            <Column alignItems="center" spacing="s3">
              <PrimaryButton isLoading={form.submitting} type="submit">
                {t('auth.confirmPhoneNumber.submit')}
              </PrimaryButton>
              <LinkButton onClick={openModal}>{t('auth.confirmPhoneNumber.resendSMS')}</LinkButton>
            </Column>
          </PairingCard>
        )}
      </Form>
      <ResendPhoneNumberConfirmationModal
        isOpen={isModalOpen}
        obfuscatedPhoneNumber={phoneNumber}
        onRequestClose={closeModal}
        onResendSMS={onResendSMS}
      />
    </>
  );
};

export default PhoneNumberConfirmationForm;
