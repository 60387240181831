import { Column, Row } from '@margobank/components/layout';
import { StickyHeader } from '@margobank/components/Modal/full-page/_styled';
import { css, mediaQuery, styled } from '@memobank/style';

export const FullSizeHeader = styled(Row)`
  width: 100%;
  flex: 0 0 auto;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PaddingWrapper = styled(Column)`
  flex: 1 0 auto;
  padding: ${({ theme }) => `${theme.spacing.s6} ${theme.spacing.s6}`};

  ${mediaQuery({ max: 'l' })(css`
    padding: ${({ theme }) => `0 ${theme.spacing.s2} ${theme.spacing.s4}`};
  `)}

  & > ${StickyHeader} {
    margin: ${({ theme }) => `0 -${theme.spacing.s2} ${theme.spacing.s3}`};

    & + * {
      margin-top: 0;
    }
  }
`;
