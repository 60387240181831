import { useState } from 'react';

import { assertIsNotNull } from '@memobank/utils';

import { getSetupStepsAsBooleans, getUnverifiedEmail, getUnverifiedPhoneNumber } from '../helpers';
import type { UserDTO } from '../types';
import { SETUP_USER_SCREEN, SETUP_USER_SCREENS } from './constants';
import type { SetupUserScreen } from './types';

type UseSetupUserFlow = {
  user: UserDTO;
  withSetupSteps?: boolean;
  withVerificationSteps?: boolean;
};

const useSetupUserFlow = ({ user, withSetupSteps, withVerificationSteps }: UseSetupUserFlow) => {
  const { missingSetupSteps } = user;

  const { shouldAcceptTerms, shouldPair2FADevice, shouldUploadIdentityProof } =
    getSetupStepsAsBooleans(missingSetupSteps);

  const needPhoneNumberValidation = getUnverifiedPhoneNumber(user);
  const needEmailValidation = getUnverifiedEmail(user);

  const screens = {
    [SETUP_USER_SCREEN.UPLOAD_IDENTITY_PROOF]: withSetupSteps && shouldUploadIdentityProof,
    [SETUP_USER_SCREEN.ACCEPT_TERMS]: withSetupSteps && shouldAcceptTerms,
    [SETUP_USER_SCREEN.PAIR_2FA_DEVICE]: withSetupSteps && shouldPair2FADevice,
    [SETUP_USER_SCREEN.VALIDATION_PHONE]: withVerificationSteps && needPhoneNumberValidation,
    [SETUP_USER_SCREEN.VALIDATION_EMAIL]: withVerificationSteps && needEmailValidation,
  };

  // Screens navigation
  const allowedScreens = SETUP_USER_SCREENS.filter((step) => screens[step]);

  // A "NULL" screen means "no screen to display", it will trigger a redirect
  const defaultScreen = allowedScreens.length > 0 ? allowedScreens[0] : null;
  const [currentScreen, setCurrentScreen] = useState<SetupUserScreen | null>(defaultScreen);

  const goToNextScreen = () => {
    assertIsNotNull(currentScreen);

    const currentIndex = allowedScreens.indexOf(currentScreen);
    const nextIndex = currentIndex + 1;

    setCurrentScreen(allowedScreens[nextIndex] ?? null);
  };

  return {
    allowedScreens,
    currentScreen,
    goToNextScreen,
  };
};

export default useSetupUserFlow;
