import type { CardPANLink, ExpirationDate } from 'app/customer/Cards/types';

export const formatRevealedCardNumber = (number: string, separator = '   '): string => {
  if (!/^\d{16}$/.test(number)) {
    throw Error('invalid card number');
  }

  const part1 = number.slice(0, 4);
  const part2 = number.slice(4, 8);
  const part3 = number.slice(8, 12);
  const part4 = number.slice(12, 16);
  return [part1, part2, part3, part4].join(separator);
};

export const parseEnfuceHTML = (rawHTML: string): CardPANLink => {
  const parser = new DOMParser();
  const tree = parser.parseFromString(rawHTML, 'text/html');

  const [cardNumber, cardExpirationDate, cardValidationValue] = [
    '#card-number',
    '#card-exp-date',
    '#card-cvv',
  ].map((selector) => tree.querySelector(selector)?.textContent?.trim());

  if (!cardNumber || !/^\d{16}$/.test(cardNumber)) {
    throw Error('invalid card data');
  }

  if (!cardExpirationDate || !/^\d{4}$/.test(cardExpirationDate)) {
    throw Error('invalid card data');
  }

  if (!cardValidationValue || !/^\d{3}$/.test(cardValidationValue)) {
    throw Error('invalid card data');
  }

  return {
    cardNumber,
    cardExpirationDate: parseExpirationDate(cardExpirationDate),
    cardValidationValue,
  };
};

export const parseExpirationDate = (rawString: string): ExpirationDate => {
  if (!/^\d{4}$/.test(rawString)) {
    throw Error('invalid expiration date');
  }

  return { month: rawString.slice(0, 2), shortYear: rawString.slice(2, 4) };
};

// From "455678__________" to "4556 78•• ••••".
export const formatInactiveCardNumber = (maskedNumber: string) =>
  maskedNumber
    .slice(0, -4) // Remove last 4 placeholders
    .split('_')
    .join('•') // Add dots placeholders instead of underscores
    .replace(/(.{4})/g, '$1 ') // Add a space between 4 digits groups
    .trim();
