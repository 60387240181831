import type { WrappedList } from '@margobank/components/common/types';
import type { ContractsDTO } from '@margobank/components/domain/contracts';
import type {
  DocumentFolderDTO,
  DocumentMetadataDTO,
  GrantingFileDTO,
  StakeholderDTO,
  TaxIdentificationNumberDTO,
  TaxInformationDTO,
} from '@margobank/components/domain/grantings';
import {
  createFileActionsHook,
  createMutationHook,
  createQueryHook,
  extendQueryHook,
  invalidateQueryHooks,
  STALE_TIME_LONG,
} from '@margobank/components/query-helpers';

import http, { GRANTINGS_SERVICE_PREFIX } from 'common/http';

import type {
  AssociatedUserDTO,
  CompanyDTO,
  DebtPurchaseParamsDTO,
  LoanParamsDTO,
  OverdraftParamsDTO,
  ProductDTO,
  StateCompletionEventDTO,
} from './types';

const GRANTINGS_LOAD_ASSOCIATED_USER = 'GRANTINGS_LOAD_ASSOCIATED_USER';
const GRANTINGS_LOAD_COMPANY = 'GRANTINGS_LOAD_COMPANY';
const GRANTINGS_LOAD_CONTRACTS = 'GRANTINGS_LOAD_CONTRACTS';
const GRANTINGS_LOAD_CUSTOMER_GRANTING_FILES = 'GRANTINGS_LOAD_CUSTOMER_GRANTING_FILES';
const GRANTINGS_LOAD_DEBT_PURCHASE_PARAMS = 'GRANTINGS_LOAD_DEBT_PURCHASE_PARAMS';
const GRANTINGS_LOAD_DOCUMENTS = 'GRANTINGS_LOAD_DOCUMENTS';
const GRANTINGS_LOAD_GRANTING_FILES = 'GRANTINGS_LOAD_GRANTING_FILES';
const GRANTINGS_LOAD_LOAN_PARAMS = 'GRANTINGS_LOAD_LOAN_PARAMS';
const GRANTINGS_LOAD_OVERDRAFT_PARAMS = 'GRANTINGS_LOAD_OVERDRAFT_PARAMS';
const GRANTINGS_LOAD_PRODUCTS = 'GRANTINGS_LOAD_PRODUCTS';
const GRANTINGS_LOAD_STAKEHOLDERS = 'GRANTINGS_LOAD_STAKEHOLDERS';
const GRANTINGS_LOAD_STATE_COMPLETION_EVENTS = 'GRANTINGS_LOAD_STATE_COMPLETION_EVENTS';

type UseContractFileActions = {
  contractId: string;
  grantingFileId: string;
};

export const useContractFileActions = createFileActionsHook(
  async ({ contractId, grantingFileId }: UseContractFileActions) => {
    const { data } = await http.get<Blob>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/contracts/${contractId}/file`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseInstallmentScheduleFileActions = {
  grantingFileId: string;
};

export const useInstallmentScheduleFileActions = createFileActionsHook(
  async ({ grantingFileId }: UseInstallmentScheduleFileActions) => {
    const { data } = await http.get<Blob>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/loan/installment_timetable`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseLoadAssociatedUser = {
  grantingFileId: string;
};

export const useLoadAssociatedUser = createQueryHook(
  GRANTINGS_LOAD_ASSOCIATED_USER,
  async ({ grantingFileId }: UseLoadAssociatedUser) => {
    const { data } = await http.get<AssociatedUserDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/associated_user`,
    );
    return data;
  },
);

type UseLoadCompany = {
  grantingFileId: string;
};

export const useLoadCompany = createQueryHook(
  GRANTINGS_LOAD_COMPANY,
  async ({ grantingFileId }: UseLoadCompany) => {
    const { data } = await http.get<CompanyDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/company`,
    );
    return data;
  },
);

type UseLoadContracts = {
  grantingFileId: string;
};

export const useLoadContracts = createQueryHook(
  GRANTINGS_LOAD_CONTRACTS,
  async ({ grantingFileId }: UseLoadContracts) => {
    const { data } = await http.get<ContractsDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/contracts`,
    );
    return data;
  },
);

type UseLoadCustomerGrantingFiles = {
  customerId: string;
};

export const useLoadCustomerGrantingFiles = createQueryHook(
  GRANTINGS_LOAD_CUSTOMER_GRANTING_FILES,
  async ({ customerId }: UseLoadCustomerGrantingFiles) => {
    const { data } = await http.get<WrappedList<GrantingFileDTO>>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/mine`,
      { params: { customerId } },
    );
    return data.results;
  },
);

type UseLoadDebtPurchaseParams = {
  grantingFileId: string;
};

export const useLoadDebtPurchaseParams = createQueryHook(
  GRANTINGS_LOAD_DEBT_PURCHASE_PARAMS,
  async ({ grantingFileId }: UseLoadDebtPurchaseParams) => {
    const { data } = await http.get<DebtPurchaseParamsDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/debt_purchase/params`,
    );
    return data;
  },
);

type UseLoadDocuments = {
  grantingFileId: string;
};

export const useLoadDocuments = createQueryHook(
  GRANTINGS_LOAD_DOCUMENTS,
  async ({ grantingFileId }: UseLoadDocuments) => {
    const { data } = await http.get<WrappedList<DocumentFolderDTO>>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/document_folders`,
    );
    return data.results;
  },
);

const useLoadAllGrantingFiles = createQueryHook(GRANTINGS_LOAD_GRANTING_FILES, async () => {
  const { data } = await http.get<WrappedList<GrantingFileDTO>>(
    `${GRANTINGS_SERVICE_PREFIX}/granting_files/mine`,
  );
  return data.results;
});

export const useLoadGrantingFiles = extendQueryHook(useLoadAllGrantingFiles, {
  select: (grantingFiles) => grantingFiles?.filter(({ state }) => state !== 'REFUSED'),
  staleTime: STALE_TIME_LONG,
});

type UseGrantingDocumentFileActions = {
  documentId: string;
  grantingFileId: string;
};

export const useGrantingDocumentFileActions = createFileActionsHook(
  async ({ documentId, grantingFileId }: UseGrantingDocumentFileActions) => {
    const { data } = await http.get<Blob>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/documents/${documentId}`,
      { responseType: 'blob' },
    );
    return data;
  },
);

type UseLoadLoanParams = {
  grantingFileId: string;
};

export const useLoadLoanParams = createQueryHook(
  GRANTINGS_LOAD_LOAN_PARAMS,
  async ({ grantingFileId }: UseLoadLoanParams) => {
    const { data } = await http.get<LoanParamsDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/loan/params`,
    );
    return data;
  },
);

type UseLoadOverdraftParams = {
  grantingFileId: string;
};

export const useLoadOverdraftParams = createQueryHook(
  GRANTINGS_LOAD_OVERDRAFT_PARAMS,
  async ({ grantingFileId }: UseLoadOverdraftParams) => {
    const { data } = await http.get<OverdraftParamsDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/overdraft/params`,
    );
    return data;
  },
);

export const useLoadPendingGrantingFiles = extendQueryHook(useLoadAllGrantingFiles, {
  select: (grantingFiles: GrantingFileDTO[]) =>
    grantingFiles?.filter(({ state }) => state !== 'SIGNED' && state !== 'REFUSED'),
});

type UseLoadProducts = {
  grantingFileId: string;
};

export const useLoadProducts = createQueryHook(
  GRANTINGS_LOAD_PRODUCTS,
  async ({ grantingFileId }: UseLoadProducts) => {
    const { data } = await http.get<WrappedList<ProductDTO>>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/available_products`,
    );
    return data.results;
  },
);

export const useLoadSelectedProducts = extendQueryHook(useLoadProducts, {
  select: (products) => products.filter(({ isSelected }) => isSelected),
});

type UseLoadStakeholders = {
  grantingFileId: string;
};

export const useLoadStakeholders = createQueryHook(
  GRANTINGS_LOAD_STAKEHOLDERS,
  async ({ grantingFileId }: UseLoadStakeholders) => {
    const { data } = await http.get<WrappedList<StakeholderDTO>>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/stakeholders`,
    );
    return data.results;
  },
);

type UseLoadStateCompletionEvents = {
  grantingFileId: string;
};

export const useLoadStateCompletionEvents = createQueryHook(
  GRANTINGS_LOAD_STATE_COMPLETION_EVENTS,
  async ({ grantingFileId }: UseLoadStateCompletionEvents) => {
    const { data } = await http.get<WrappedList<StateCompletionEventDTO>>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/state_completions`,
    );
    return data.results;
  },
);

/**
 * Mutations
 */

type UseCreateAccess = {
  grantingFileId: string;
};

export const useCreateAccess = createMutationHook(
  async ({ grantingFileId }: UseCreateAccess) => {
    const { data } = await http.post(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/access`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadAllGrantingFiles),
  },
);

type UseCreateStakeholder = {
  grantingFileId: string;
  stakeholder: StakeholderDTO;
};

export const useCreateStakeholder = createMutationHook(
  async ({ grantingFileId, stakeholder }: UseCreateStakeholder) => {
    const { data } = await http.post<StakeholderDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/stakeholders`,
      stakeholder,
    );
    return data;
  },
);

type UseDeleteDocument = {
  documentId: string;
  grantingFileId: string;
};

export const useDeleteDocument = createMutationHook(
  async ({ documentId, grantingFileId }: UseDeleteDocument) => {
    const { data } = await http.delete(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/documents/${documentId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAllGrantingFiles, useLoadDocuments]),
  },
);

type UseDeleteStakeholder = {
  grantingFileId: string;
  stakeholderId: string;
};

export const useDeleteStakeholder = createMutationHook(
  async ({ grantingFileId, stakeholderId }: UseDeleteStakeholder) => {
    const { data } = await http.delete(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/stakeholders/${stakeholderId}`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadStakeholders),
  },
);

type UseUpdateCardCurrentAccountArgs = {
  grantingFileId: string;
  params: TaxInformationDTO;
};

export const useUpdateCompanyTaxInformation = createMutationHook(
  async ({ grantingFileId, params }: UseUpdateCardCurrentAccountArgs) => {
    const { data } = await http.put<CompanyDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/company_tax_information`,
      params,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadCompany),
  },
);

type UseUpdateCompany = {
  company: CompanyDTO;
  grantingFileId: string;
};

export const useUpdateCompany = createMutationHook(
  async ({ company, grantingFileId }: UseUpdateCompany) => {
    const { data } = await http.put(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/company`,
      company,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadCompany),
  },
);

type UseUpdateStakeholder = {
  grantingFileId: string;
  stakeholder: StakeholderDTO;
  stakeholderId: string;
};

export const useUpdateStakeholder = createMutationHook(
  async ({ grantingFileId, stakeholder, stakeholderId }: UseUpdateStakeholder) => {
    const { data } = await http.put(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/stakeholders/${stakeholderId}`,
      stakeholder,
    );
    return data;
  },
);

type UseUpdateStakeholderTaxInformation = {
  grantingFileId: string;
  stakeholderId: string;
  taxInformation: TaxIdentificationNumberDTO;
};

export const useUpdateStakeholderTaxInformation = createMutationHook(
  async ({ grantingFileId, stakeholderId, taxInformation }: UseUpdateStakeholderTaxInformation) => {
    const { data } = await http.put<StakeholderDTO>(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/stakeholders/${stakeholderId}/tax_information`,
      taxInformation,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadStakeholders),
  },
);

type UseUploadDocument = {
  file: File;
  grantingFileId: string;
  metadata: DocumentMetadataDTO;
};

export const useUploadDocument = createMutationHook(
  async ({ file, grantingFileId, metadata }: UseUploadDocument) => {
    const formData = new FormData();
    const metadataAsJson = JSON.stringify(metadata);
    formData.append('data', new Blob([metadataAsJson], { type: 'application/json' }));
    formData.append('file', file);
    const { data } = await http.post(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/documents`,
      formData,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks([useLoadAllGrantingFiles, useLoadDocuments]),
  },
);

type UseValidateGrantingFile = {
  grantingFileId: string;
};

export const useValidateGrantingFile = createMutationHook(
  async ({ grantingFileId }: UseValidateGrantingFile) => {
    const { data } = await http.post(
      `${GRANTINGS_SERVICE_PREFIX}/granting_files/${grantingFileId}/customer_validate`,
    );
    return data;
  },
  {
    onSuccess: invalidateQueryHooks(useLoadAllGrantingFiles),
  },
);
