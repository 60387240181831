import { createGlobalStyle, css, mediaQuery, styled } from '@memobank/style';

import { HELP_BEACON_THEME } from '../constants';
import type { HelpBeaconTheme } from '../types';

export const HelpBeaconStyleOverride = createGlobalStyle<{
  beaconTheme: HelpBeaconTheme;
}>`
  .BeaconContainer {
    ${({ beaconTheme }) => css`
      ${beaconTheme === HELP_BEACON_THEME.PRIMARY &&
      css`
        bottom: ${({ theme }) => theme.spacing.s2} !important;
        left: ${({ theme }) => theme.metric.newSidebarWith} !important;
        margin-left: ${({ theme }) => theme.spacing.s0_5} !important;
      `};

      ${beaconTheme === HELP_BEACON_THEME.SECONDARY &&
      css`
        bottom: ${({ theme }) => theme.metric.onboardingHelpBeaconBottomOffset} !important;
        left: ${({ theme }) => theme.metric.onboardingHelpBeaconLeftOffset} !important;
        margin-left: 0 !important;
      `}
    `}

    ${mediaQuery({ max: 'l' })(css`
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      right: 0 !important;
      margin: auto !important;
    `)}
  }
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.color.overlayBackground};
  z-index: ${({ theme }) => theme.zIndex.modal};
`;
