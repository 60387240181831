import { useCallback, useState } from 'react';

import { BackNavigationContext } from './hooks/useBackNavigation';

const BackNavigationProvider = ({ children }) => {
  const [urlQueue, setUrlQueue] = useState<string[]>([]);

  const pushUrl = useCallback(
    (url: string) => setUrlQueue((prevUrlQueue) => [url, ...prevUrlQueue]),
    [setUrlQueue],
  );

  const removeUrl = useCallback(
    (url: string) => setUrlQueue((prevQueue) => prevQueue.filter((u) => u !== url)),
    [setUrlQueue],
  );

  return (
    <BackNavigationContext.Provider value={{ urlQueue, pushUrl, removeUrl }}>
      {children}
    </BackNavigationContext.Provider>
  );
};

export default BackNavigationProvider;
