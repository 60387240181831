import type { WrappedList } from '@margobank/components/common/types';
import { createQueryHook, extendQueryHook } from '@margobank/components/query-helpers';

import http, { USERS_SERVICE_PREFIX, X_AUTH_TOKEN } from 'common/http';

import type {
  ApprovalRequestDTO,
  DeviceInformationDTO,
  IdentityProofDTO,
  InvitationDTO,
  RequestPhoneNumberVerificationResponseDTO,
  SessionUserDTO,
  UserDTO,
} from './types';

const AUTH_LOAD_APPROVAL_REQUEST = 'AUTH_LOAD_APPROVAL_REQUEST';
const AUTH_LOAD_DEVICES_INFORMATION = 'AUTH_LOAD_DEVICES_INFORMATION';
const AUTH_LOAD_IDENTITY_PROOFS = 'AUTH_LOAD_IDENTITY_PROOFS';
const AUTH_LOAD_INVITATION = 'AUTH_LOAD_INVITATION';
const AUTH_LOAD_TEMPORARY_USER = 'AUTH_LOAD_TEMPORARY_USER';
const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
const AUTH_REQUEST_PHONE_NUMBER_VERIFICATION = 'AUTH_REQUEST_PHONE_NUMBER_VERIFICATION';

type UseLoadApprovalRequestArgs = {
  approvalRequestId: string;
  token?: string;
  userId: string;
};

export const useLoadApprovalRequest = createQueryHook(
  AUTH_LOAD_APPROVAL_REQUEST,
  async ({ approvalRequestId, token, userId }: UseLoadApprovalRequestArgs) => {
    const headers = token ? { [X_AUTH_TOKEN]: token } : {};
    const { data } = await http.get<ApprovalRequestDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/approval_requests/${approvalRequestId}`,
      { headers },
    );
    return data;
  },
);

type UseLoadDevicesInformationArgs = {
  userId: string;
};

export const useLoadDevicesInformation = createQueryHook(
  AUTH_LOAD_DEVICES_INFORMATION,
  async ({ userId }: UseLoadDevicesInformationArgs) => {
    const { data } = await http.get<DeviceInformationDTO[]>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/devices`,
    );
    return data;
  },
);

export const useLoadPairedDeviceInformation = extendQueryHook(useLoadDevicesInformation, {
  select: (devicesInformation) => devicesInformation.find(({ name, paired }) => paired && name),
});

export const useLoadUnpairedDeviceInformation = extendQueryHook(useLoadDevicesInformation, {
  select: (devicesInformation) => devicesInformation.find(({ name, paired }) => !paired && name),
});

type UseLoadIdentityProofs = {
  userId: string;
};

export const useLoadIdentityProofs = createQueryHook(
  AUTH_LOAD_IDENTITY_PROOFS,
  async ({ userId }: UseLoadIdentityProofs) => {
    const { data } = await http.get<WrappedList<IdentityProofDTO>>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/identity_proof_candidates`,
    );
    return data.results;
  },
);

type UseLoadInvitation = {
  token: string;
};

export const useLoadInvitation = createQueryHook(
  AUTH_LOAD_INVITATION,
  async ({ token }: UseLoadInvitation) => {
    const { data } = await http.get<InvitationDTO>(`${USERS_SERVICE_PREFIX}/invitations/${token}`);
    return data;
  },
);

type UseLoadTemporaryUserArgs = {
  token: string;
};

export const useLoadTemporaryUser = createQueryHook(
  AUTH_LOAD_TEMPORARY_USER,
  async ({ token }: UseLoadTemporaryUserArgs) => {
    const { data } = await http.get<SessionUserDTO>(`${USERS_SERVICE_PREFIX}/sessions/me/user`, {
      headers: { [X_AUTH_TOKEN]: token },
    });
    return data;
  },
);

export const useLoadUser = createQueryHook(AUTH_LOAD_USER, async () => {
  const { data } = await http.get<UserDTO>(`${USERS_SERVICE_PREFIX}/users/me`);
  return data;
});

type UseRequestPhoneNumberVerification = {
  invitationToken?: string;
  resetPasswordToken?: string;
};

export const useRequestPhoneNumberVerification = createQueryHook(
  AUTH_REQUEST_PHONE_NUMBER_VERIFICATION,
  async ({ invitationToken, resetPasswordToken }: UseRequestPhoneNumberVerification) => {
    const { data } = await http.post<RequestPhoneNumberVerificationResponseDTO>(
      `${USERS_SERVICE_PREFIX}/verification/request`,
      { invitationToken, resetPasswordToken },
    );
    return data;
  },
  {
    // We use an infinite stale time and cache time to avoid unexpected calls here.
    // Each call sends an SMS to the user.
    cacheTime: Infinity,
    select: ({ obfuscatedPhoneNumber }) => obfuscatedPhoneNumber,
    staleTime: Infinity,
  },
);
