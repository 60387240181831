import { createContext, useContext, useEffect } from 'react';

type TBackNavigationContext = {
  pushUrl: (url: string) => void;
  removeUrl: (url: string) => void;
  urlQueue: string[];
};

export const BackNavigationContext = createContext<TBackNavigationContext | null>(null);

const useBackNavigation = (href?: string) => {
  const context = useContext(BackNavigationContext);
  if (!context) {
    throw Error('useBackNavigation must be used within a <BackNavigationProvider />');
  }

  const { pushUrl, removeUrl, urlQueue } = context;

  useEffect(() => {
    href && pushUrl(href);
    return () => {
      href && removeUrl(href);
    };
  }, [href, pushUrl, removeUrl]);

  const [url = null] = urlQueue;
  return url;
};

export default useBackNavigation;
