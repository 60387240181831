import type { ReactNode } from 'react';

import { Column } from '@margobank/components/layout';
import { Body, Subheading } from '@margobank/components/text';

type Props = {
  body: string;
  footerElement?: ReactNode;
  stickerElement: ReactNode;
  title: string;
};

const SecondFactorValidationLayout = ({ body, footerElement, stickerElement, title }: Props) => {
  return (
    <Column alignItems="center" alignSelf="center" padding="s3" spacing="s3" textAlign="center">
      {stickerElement}
      <Column spacing="s1">
        <Subheading>{title}</Subheading>
        <Body subdued>{body}</Body>
      </Column>
      {footerElement}
    </Column>
  );
};

export default SecondFactorValidationLayout;
