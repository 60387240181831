import type { ComponentProps } from 'react';

import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { StickerIcon } from '@margobank/components/Sticker';
import { Body, Subheading } from '@margobank/components/text';

import { useHelpBeacon } from 'components/help-beacon';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onRetry: () => void;
};

const Rejected = ({ onRetry, ...rest }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { t } = useIntl();

  return (
    <PairingLayout {...rest} title={t('auth.updatePairing.title')}>
      <PairingCard>
        <Column alignItems="center" maxWidth="medium" spacing="s3" textAlign="center">
          <StickerIcon icon="large/cross" size="x-large" variant="red" />
          <Column spacing="s1">
            <Subheading>{t('auth.updatePairing.rejected.title')}</Subheading>
            <Body subdued>{t('auth.updatePairing.rejected.body')}</Body>
          </Column>
          <PrimaryButton large onClick={onRetry}>
            {t('auth.updatePairing.rejected.retry')}
          </PrimaryButton>
          <LinkButton onClick={() => openMessageBox()}>
            {t('auth.secondFactorValidation.contactButton')}
          </LinkButton>
        </Column>
      </PairingCard>
    </PairingLayout>
  );
};

export default Rejected;
