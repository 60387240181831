import type { ComponentProps } from 'react';

import { useIntl } from '@margobank/components/intl';
import { Heading } from '@margobank/components/text';

import SecondFactorValidation from 'components/SecondFactorValidation';

import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'onPrevious'> &
  Omit<ComponentProps<typeof SecondFactorValidation>, 'renderTitle'> & {
    userId: string;
  };

const PairingSecondFactorValidation = ({ deviceName, userId, ...rest }: Props) => {
  const { t } = useIntl();

  return (
    <PairingLayout
      {...rest}
      subtitle={t('auth.updatePairing.secondFactorValidation.subtitle')}
      title={t('auth.updatePairing.title')}
    >
      <SecondFactorValidation
        {...rest}
        asCard
        deviceName={deviceName}
        renderTitle={({ deviceName }) => (
          <Heading>{t('auth.updatePairing.secondFactorValidation.body', { deviceName })}</Heading>
        )}
        userId={userId}
      />
    </PairingLayout>
  );
};

export default PairingSecondFactorValidation;
