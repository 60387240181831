import type { ComponentType } from 'react';
import { Redirect } from 'react-router-dom';

import type { GrantingFileDTO } from '@margobank/components/domain/grantings';

import { selectUser } from 'app/auth/selectors';
import type { UserDTO } from 'app/auth/types';
import { useLoadGrantingFileFromRouteParam } from 'app/grantings/hooks';
import { useLoadGrantingFiles } from 'app/grantings/queries';
import { useSelector } from 'common/store';

import type { RouteComponentProps, RouteProps } from '.';
import { PrivateRoute, Route } from '.';
import { getGrantingHomePath, getLastActiveWorkspace } from './helpers';

type GrantingsRouteProps = {
  grantingFile: GrantingFileDTO;
};

type Props = RouteProps & {
  component: ComponentType<RouteComponentProps & GrantingsRouteProps>;
};

const GrantingsRoute = ({ component: Component }: Props) => {
  const [grantingFiles, { LoadingState }] = useLoadGrantingFiles();
  const grantingFile = useLoadGrantingFileFromRouteParam();

  const user = useSelector(selectUser) as UserDTO;

  const lastActiveGrantingFile = grantingFiles && getLastActiveWorkspace(grantingFiles);

  if (typeof grantingFiles === 'undefined') {
    return <LoadingState />;
  }

  // If we don't have any granting files, redirect to the homepage.
  if (!grantingFiles.length) {
    return <Redirect to="/" />;
  }

  // The hash is invalid, redirect to the last active onboarding.
  if (!grantingFile) {
    return <Redirect to={getGrantingHomePath(lastActiveGrantingFile?.hash)} />;
  }

  // Redirect once user has signed & paired his device
  if (grantingFile.state === 'SIGNED' && user.pairedDevice?.paired) {
    // Will redirect to the last active customer
    return <Redirect to="/" />;
  }

  return (
    <Route render={(routerProps) => <Component {...routerProps} grantingFile={grantingFile} />} />
  );
};

const PrivateGrantingsRoute = (props: Props) => {
  const { component, render, ...rest } = props;
  return (
    <PrivateRoute
      {...rest}
      render={(routerProps) => <GrantingsRoute {...routerProps} {...props} />}
      withVerificationSteps
    />
  );
};

export default PrivateGrantingsRoute;
