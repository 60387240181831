import type { ReactNode } from 'react';

import { AVAILABLE_FEATURE, useFeature } from '@margobank/components/feature-toggles';
import { IntlAsyncProvider } from '@margobank/components/intl';

import { useLocale } from 'app/auth/hooks';
import type { Locale } from 'common/types';

import { getFormatters } from './formatters';

const loadTranslations = async (locale: Locale) => {
  const { default: data } = await import(`../translations/${locale}.json`);
  return data;
};

type Props = {
  children: ReactNode;
  forcedLocale?: Locale;
};

const IntlProvider = ({ children, forcedLocale }: Props) => {
  const { locale: currentLocale } = useLocale();
  const isDebugModeEnabled = useFeature(AVAILABLE_FEATURE.DEBUG_LOCALES);

  const locale = forcedLocale ?? currentLocale;

  return (
    <IntlAsyncProvider
      getFormatters={getFormatters}
      loadTranslations={loadTranslations}
      locale={locale}
      showTranslationKeys={isDebugModeEnabled}
    >
      {children}
    </IntlAsyncProvider>
  );
};

export default IntlProvider;
