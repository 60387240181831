import { useRouteMatch } from 'react-router-dom';

import { getCustomerHomePath, getLastActiveWorkspace } from 'components/routes/helpers';

import { useLoadCustomers } from '../queries';

const useCustomer = () => {
  const match = useRouteMatch<{ hash?: string }>(getCustomerHomePath(':hash'));
  const { hash: customerHash } = match?.params || {};

  const [customers] = useLoadCustomers(undefined, { suspense: true });
  const customerFromHash = (customers ?? []).find(({ hash }) => hash === customerHash);

  // Add a fallback to the last active customer if he's not found.
  // It displays the menu and dropdown in the "not found page".
  const customer = customerFromHash ?? getLastActiveWorkspace(customers);

  if (!customer) {
    throw Error('Missing current customer');
  }

  return customer;
};

export default useCustomer;
