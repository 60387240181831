import { LinkButton, PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { StickerIcon } from '@margobank/components/Sticker';
import { Body, ErrorMessage, Subheading } from '@margobank/components/text';

import { getUnverifiedPhoneNumber } from 'app/auth/helpers';
import { useRequestPhoneNumberUpdatedVerification } from 'app/auth/mutations';
import type { UserDTO } from 'app/auth/types';
import { useHelpBeacon } from 'components/help-beacon';

import ValidationCard from '../../_parts/ValidationCard';
import ValidationLayout from '../../_parts/ValidationLayout';

type Props = {
  error?: string;
  onConfirm: () => void;
  user: UserDTO;
};

const ConfirmPhoneNumber = ({ error, onConfirm, user }: Props) => {
  const { openMessageBox } = useHelpBeacon();
  const { formatPhone, t } = useIntl();
  const [requestPhoneNumberUpdatedVerification] = useRequestPhoneNumberUpdatedVerification();

  const unverifiedPhoneNumber = getUnverifiedPhoneNumber(user);

  if (!unverifiedPhoneNumber) {
    throw Error('unverifiedPhoneNumber must be defined');
  }

  const handleConfirm = () => {
    requestPhoneNumberUpdatedVerification({ userId: user.id });
    onConfirm();
  };

  return (
    <ValidationLayout title={t('auth.validation.phoneNumber.title')}>
      <ValidationCard>
        <StickerIcon icon="large/cellphone_with-rotating-arrows" size="x-large" variant="blue" />
        <Column spacing="s2">
          <Column spacing="s1">
            <Subheading>{t('auth.validation.phoneNumber.intro.title')}</Subheading>
            <Body>{t('auth.validation.phoneNumber.intro.content')}</Body>
          </Column>
          <Body>
            {t('auth.validation.phoneNumber.intro.newNumber', {
              em: (chunk) => (
                <Body bold highlighted inline>
                  {chunk}
                </Body>
              ),
              phoneNumber: formatPhone(unverifiedPhoneNumber),
            })}
          </Body>
        </Column>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <PrimaryButton onClick={handleConfirm}>
          {t('auth.validation.phoneNumber.intro.verify')}
        </PrimaryButton>
        <LinkButton onClick={() => openMessageBox()}>
          {t('auth.validation.phoneNumber.intro.signalError')}
        </LinkButton>
      </ValidationCard>
    </ValidationLayout>
  );
};

export default ConfirmPhoneNumber;
