import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { RouterContext } from './Router';

export const usePagePath = () => {
  const location = useLocation();
  const { paramsToCanonicalize } = useRouter();

  // Build path from URL, excluding search params
  const rawPagePath = [location.pathname, location.hash].join('');

  // Replace page params by their name
  const pagePath = Object.entries(paramsToCanonicalize).reduce((prev, [param, value]) => {
    return prev.replace(`/${value}`, `/:${param}`);
  }, rawPagePath);

  return pagePath;
};

export const useRouter = () => {
  const routerContext = useContext(RouterContext);
  if (!routerContext) {
    throw Error('useRouter must be used inside a Router component');
  }
  return routerContext;
};
