import { Base64 } from 'js-base64';
import { createAction, createAsyncAction } from 'redux-promise-middleware-actions';

import type { SessionDTO } from '@margobank/components/domain/auth/types';

import type {
  AuthError,
  ConfirmPhoneNumberVerificationRequestDTO,
  LoginRequestDTO,
  PairingQRCodeDTO,
  RequestDeviceReplacementResponseDTO,
  ResetPasswordDTO,
  SetupPasswordDTO,
  UserAndSessionDTO,
  UserDTO,
} from 'app/auth/types';
import http, { USERS_SERVICE_PREFIX, X_APPROVAL_REQUEST_ID, X_SMS_OTP } from 'common/http';
import queryClient from 'common/queryClient';

export const AUTH_CLEAR_DEVICE_REPLACEMENT_TOKEN = 'AUTH_CLEAR_DEVICE_REPLACEMENT_TOKEN';
export const AUTH_CLEAR_PHONE_NUMBER_TOKEN = 'AUTH_CLEAR_PHONE_NUMBER_TOKEN';
export const AUTH_CONFIRM_EMAIL_UPDATED_VERIFICATION = 'AUTH_CONFIRM_EMAIL_UPDATED_VERIFICATION';
export const AUTH_CONFIRM_NEW_PAIRED_DEVICE = 'AUTH_CONFIRM_NEW_PAIRED_DEVICE';
export const AUTH_CONFIRM_PHONE_NUMBER_UPDATED_VERIFICATION =
  'AUTH_CONFIRM_PHONE_NUMBER_UPDATED_VERIFICATION';
export const AUTH_CONFIRM_PHONE_NUMBER_VERIFICATION = 'AUTH_CONFIRM_PHONE_NUMBER_VERIFICATION';
export const AUTH_KEEP_ALIVE = 'AUTH_KEEP_ALIVE';
export const AUTH_LOAD_PAIRING_QR_CODE = 'AUTH_LOAD_PAIRING_QR_CODE';
export const AUTH_LOAD_USER = 'AUTH_LOAD_USER';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_REQUEST_DEVICE_REPLACEMENT = 'AUTH_REQUEST_DEVICE_REPLACEMENT';
export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD';
export const AUTH_SETUP_PASSWORD = 'AUTH_SETUP_PASSWORD';

export const clearDeviceReplacementVerificationToken = createAction(
  AUTH_CLEAR_DEVICE_REPLACEMENT_TOKEN,
);

export const clearPhoneNumberVerificationToken = createAction(AUTH_CLEAR_PHONE_NUMBER_TOKEN);

export const confirmEmailUpdatedVerification = createAsyncAction(
  AUTH_CONFIRM_EMAIL_UPDATED_VERIFICATION,
  async (userId: string, verificationCode: string, approvalRequestId?: string) => {
    const headers = approvalRequestId ? { [X_APPROVAL_REQUEST_ID]: approvalRequestId } : {};
    const { data } = await http.put<UserDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/email_update/confirm`,
      { verificationCode },
      { headers },
    );
    return data;
  },
);

export const confirmNewPairedDevice = createAsyncAction(
  AUTH_CONFIRM_NEW_PAIRED_DEVICE,
  async (userId: string, deviceId: string, approvalRequestId?: string) => {
    const headers = approvalRequestId ? { [X_APPROVAL_REQUEST_ID]: approvalRequestId } : {};
    const { data } = await http.put(
      `${USERS_SERVICE_PREFIX}/users/${userId}/devices/${deviceId}/confirm`,
      {},
      { headers },
    );
    return data;
  },
);

export const confirmPhoneNumberVerification = createAsyncAction(
  AUTH_CONFIRM_PHONE_NUMBER_VERIFICATION,
  async ({ code, invitationToken }: ConfirmPhoneNumberVerificationRequestDTO) => {
    const { data } = await http.post(`${USERS_SERVICE_PREFIX}/verification/confirm`, {
      invitationToken,
      code,
    });
    return data;
  },
);

export const confirmPhoneNumberUpdatedVerification = createAsyncAction(
  AUTH_CONFIRM_PHONE_NUMBER_UPDATED_VERIFICATION,
  async (userId: string, verificationCode: string, approvalRequestId?: string) => {
    const headers = approvalRequestId ? { [X_APPROVAL_REQUEST_ID]: approvalRequestId } : {};
    const { data } = await http.put<UserDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/phone_number_update/confirm`,
      { verificationCode },
      { headers },
    );
    return data;
  },
);

export const keepAlive = createAsyncAction(AUTH_KEEP_ALIVE, async () => {
  const { data } = await http.put<SessionDTO>(`${USERS_SERVICE_PREFIX}/sessions/me/keep_alive`);
  return data;
});

export const loadPairingQRcode = createAsyncAction(
  AUTH_LOAD_PAIRING_QR_CODE,
  async (userId: string, verificationToken: string) => {
    const { data } = await http.post<PairingQRCodeDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/device_pairing_qr_code`,
      { verificationToken },
    );
    return data;
  },
);

export const loadUser = createAsyncAction(AUTH_LOAD_USER, async () => {
  const { data } = await http.get<UserDTO>(`${USERS_SERVICE_PREFIX}/users/me`);
  return data;
});

export const login = createAsyncAction(
  AUTH_LOGIN,
  async ({ email, password }: LoginRequestDTO, approvalRequestId?: string) => {
    const headers = approvalRequestId ? { [X_APPROVAL_REQUEST_ID]: approvalRequestId } : {};
    const { data } = await http.post<UserAndSessionDTO>(
      `${USERS_SERVICE_PREFIX}/sessions`,
      {
        email,
        password: Base64.encode(password),
      },
      { headers },
    );
    return data;
  },
);

export const logout = createAsyncAction(
  AUTH_LOGOUT,
  async () => {
    const { data } = await http.delete(`${USERS_SERVICE_PREFIX}/sessions/me`);
    // Clear react-query cache
    queryClient.clear();
    return data;
  },
  (authError?: AuthError) => ({ authError }),
);

export const requestDeviceReplacement = createAsyncAction(
  AUTH_REQUEST_DEVICE_REPLACEMENT,
  async (userId: string) => {
    const { data } = await http.post<RequestDeviceReplacementResponseDTO>(
      `${USERS_SERVICE_PREFIX}/users/${userId}/device_replacement`,
    );
    return data;
  },
);

export const resetPassword = createAsyncAction(
  AUTH_RESET_PASSWORD,
  async ({ newPassword, token }: ResetPasswordDTO, approvalRequestId?: string, code?: string) => {
    const headers = approvalRequestId
      ? { [X_APPROVAL_REQUEST_ID]: approvalRequestId }
      : code
        ? { [X_SMS_OTP]: code }
        : {};
    const { data } = await http.post(
      `${USERS_SERVICE_PREFIX}/reset_password`,
      {
        newPassword: Base64.encode(newPassword),
        token,
      },
      { headers },
    );
    return data;
  },
);

// ℹ️ The response is used to refresh the user’s token (cf. reducer).
// so migrating this to use react-query should be done last.
export const setupPassword = createAsyncAction(
  AUTH_SETUP_PASSWORD,
  async ({ invitationToken, password, phoneNumberVerificationToken }: SetupPasswordDTO) => {
    const { data } = await http.post<UserAndSessionDTO>(
      `${USERS_SERVICE_PREFIX}/users/me/password`,
      {
        invitationToken,
        password: Base64.encode(password),
        phoneNumberVerificationToken,
      },
    );
    return data;
  },
);
