import Button from '@margobank/components/Button/Button';
import { styled } from '@memobank/style';
import { Icon } from '@memobank/ui';

const HelpButtonSecondary = styled(Button).attrs({ iconPrefix: 'medium/buoy' })`
  border: 1px solid ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.graySubdued};
  background: ${({ theme }) => theme.color.grayPureBackground};
  border-radius: ${({ theme }) => theme.spacing.s3};
  box-shadow: ${({ theme }) => `0 ${theme.spacing.s3} ${theme.spacing.s4} 0 ${theme.color.shadow}`};

  ${Icon} {
    color: ${({ theme }) => theme.color.graySubdued};
  }
`;

export default HelpButtonSecondary;
