import type { ComponentProps } from 'react';
import { useMount } from 'react-use';

import { PrimaryButton } from '@margobank/components/Button';
import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import { useLoadingState } from '@margobank/components/Loader';
import { Body, Subheading } from '@margobank/components/text';

import { requestDeviceReplacement } from 'app/auth/actions';
import { useLoadDevicesInformation } from 'app/auth/queries';
import unlockDevices from 'assets/images/phones-on.svg';
import { useDispatch } from 'common/store';

import PairingCard from '../../../_parts/PairingCard';
import PairingLayout from '../../../_parts/PairingLayout';

type Props = Pick<ComponentProps<typeof PairingLayout>, 'closeButton' | 'onPrevious'> & {
  onConfirm: () => void;
  userId: string;
};

const TurnOnDevices = ({ onConfirm, userId, ...rest }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const { LoadingState, error, isLoading, watch } = useLoadingState();

  const [blankDevice, { refetch: refetchDevicesInformation }] = useLoadDevicesInformation(
    { userId },
    {
      enabled: false,
      select: (devicesInformation) => devicesInformation.find(({ name }) => !name),
    },
  );

  useMount(() => {
    watch(dispatch(requestDeviceReplacement(userId)).then(() => refetchDevicesInformation()));
  });

  return (
    <PairingLayout {...rest} title={t('auth.updatePairing.title')}>
      {isLoading || error || !blankDevice ? (
        <Column alignItems="center">
          <LoadingState />
        </Column>
      ) : (
        <PairingCard>
          <img
            alt={t('auth.updatePairing.turnOnDevices.imgCaption')}
            height="140"
            src={unlockDevices}
            width="124"
          />
          <Column spacing="s1">
            <Subheading>{t('auth.updatePairing.turnOnDevices.intro')}</Subheading>
            <Body subdued>{t('auth.updatePairing.turnOnDevices.body')}</Body>
          </Column>
          <PrimaryButton large onClick={onConfirm}>
            {t('common.actions.continue')}
          </PrimaryButton>
        </PairingCard>
      )}
    </PairingLayout>
  );
};

export default TurnOnDevices;
