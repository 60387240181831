import { useState } from 'react';

import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { Column } from '@margobank/components/layout';
import Modal from '@margobank/components/Modal';

import { UserMenuItem } from '../../_styled';
import CustomersMenuContent from './CustomersMenuContent';
import CustomersMenuTriggerContent from './CustomersMenuTriggerContent';

type Props = {
  currentWorkspace?: AuthenticatedCustomerDTO | GrantingFileDTO;
};

const CustomersMenuModal = ({ currentWorkspace }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <UserMenuItem onClick={() => setIsModalOpen(true)}>
        <CustomersMenuTriggerContent />
      </UserMenuItem>
      <Modal
        isOpen={isModalOpen}
        noPadding
        onRequestClose={() => setIsModalOpen(false)}
        withCloseButton
      >
        <Column paddingY="s2">
          <CustomersMenuContent currentWorkspace={currentWorkspace} />
        </Column>
      </Modal>
    </>
  );
};

export default CustomersMenuModal;
