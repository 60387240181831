import { useEffect, useState } from 'react';

import { useIntl } from '@margobank/components/intl';
import { Enum } from '@memobank/utils';

import { APPROVAL_REQUEST_STATE } from 'app/auth/constants';
import { useLoadApprovalRequest } from 'app/auth/queries';

import SecondFactorValidationApproved from './_parts/SecondFactorValidationApproved';
import SecondFactorValidationExpired from './_parts/SecondFactorValidationExpired';
import SecondFactorValidationPending from './_parts/SecondFactorValidationPending';
import SecondFactorValidationRejected from './_parts/SecondFactorValidationRejected';
import { usePairedDeviceInfo } from './queries';
import type {
  SecondFactorValidationOptions,
  SecondFactorValidationParams,
} from './SecondFactorValidationProvider';

const REFETCH_INTERVAL_MS = 1_000;

const STEP = Enum('PENDING', 'APPROVED', 'EXPIRED', 'REJECTED');
type Step = Enum<typeof STEP>;

type Props = {
  onApprove: () => void;
  onCancel: () => void;
  options?: SecondFactorValidationOptions;
  params: SecondFactorValidationParams;
};

const SecondFactorValidationFlow = ({
  onApprove,
  onCancel,
  options,
  params: { approvalRequestId, token },
}: Props) => {
  const { t } = useIntl();

  const [currentStep, setCurrentStep] = useState<Step>(STEP.PENDING);

  const [{ deviceName, userId }, { LoadingState: PairedDeviceLoadingState }] = usePairedDeviceInfo({
    token,
  });

  const [approvalRequest] = useLoadApprovalRequest(
    { approvalRequestId, userId: userId as string, token },
    {
      enabled: !!userId,
      refetchInterval: (approvalRequest) => {
        const shouldRefetch =
          !approvalRequest?.state || approvalRequest.state === APPROVAL_REQUEST_STATE.PENDING;
        return shouldRefetch ? REFETCH_INTERVAL_MS : false;
      },
    },
  );

  useEffect(() => {
    switch (approvalRequest?.state) {
      case APPROVAL_REQUEST_STATE.APPROVED:
        setCurrentStep(STEP.APPROVED);
        onApprove();
        break;

      case APPROVAL_REQUEST_STATE.REJECTED:
        setCurrentStep(STEP.REJECTED);
        break;

      case APPROVAL_REQUEST_STATE.CANCELLED:
      case APPROVAL_REQUEST_STATE.CONSUMED:
      case APPROVAL_REQUEST_STATE.EXPIRED:
        setCurrentStep(STEP.EXPIRED);
        break;
    }
    // Don't add `onApprove` as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalRequest?.state, setCurrentStep]);

  if (!deviceName || !userId) {
    return <PairedDeviceLoadingState />;
  }

  const title =
    options?.getTitle?.({ deviceName }) ??
    t('auth.secondFactorValidation.defaultTitle', { deviceName });

  return (
    <>
      {currentStep === STEP.PENDING && (
        <SecondFactorValidationPending deviceName={deviceName} title={title} />
      )}
      {currentStep === STEP.APPROVED && <SecondFactorValidationApproved title={title} />}
      {currentStep === STEP.REJECTED && <SecondFactorValidationRejected onCancel={onCancel} />}
      {currentStep === STEP.EXPIRED && <SecondFactorValidationExpired onCancel={onCancel} />}
    </>
  );
};

export default SecondFactorValidationFlow;
