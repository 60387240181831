import { useDevice } from '@margobank/components/device';
import { useIntl } from '@margobank/components/intl';
import { Body } from '@margobank/components/text';
import Tooltip from '@margobank/components/Tooltip';
import { STATUS } from '@memobank/theme';
import { Icon } from '@memobank/ui';

import { getGrantingHomePath } from 'components/routes/helpers';

import { UserMenuItem } from '../../_styled';
import { CustomersMenuIconRow } from '../_styled';

type Props = {
  hash: string;
  isCurrent: boolean;
  name: string;
};

const GrantingItem = ({ hash, isCurrent, name }: Props) => {
  const { t } = useIntl();
  const { isTouchScreen } = useDevice();

  return (
    <UserMenuItem to={getGrantingHomePath(hash)}>
      <CustomersMenuIconRow>
        <Body bold>{name}</Body>
        {isCurrent ? (
          <Icon icon="medium/check_circled_plain" status={STATUS.INFO} />
        ) : !isTouchScreen ? (
          <Tooltip content={t('customer.userDropdown.granting')} widthAuto>
            {({ ref }) => (
              <span ref={ref}>
                <Icon icon="medium/clock" subdued />
              </span>
            )}
          </Tooltip>
        ) : (
          <Body subdued>{t('customer.userDropdown.granting')}</Body>
        )}
      </CustomersMenuIconRow>
    </UserMenuItem>
  );
};

export default GrantingItem;
