import type { AuthState } from 'app/auth/reducer';

export type PersistedState = {
  auth: AuthState;
};

export const loadState = (): PersistedState | undefined => {
  try {
    const serializedState = localStorage.getItem('state');
    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState) as PersistedState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: PersistedState) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore
  }
};
