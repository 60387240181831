import type { ComponentProps, ReactNode } from 'react';

import { useIntl } from '@margobank/components/intl';
import { Column } from '@margobank/components/layout';
import {
  FullPageModalContainer,
  FullPageModalHeader,
  FullPageModalMediumSection,
} from '@margobank/components/Modal/full-page';

type Props = {
  children: ReactNode;
  closeButton?: ComponentProps<typeof FullPageModalHeader>['closeButton'];
  onPrevious?: () => void;
  rightComponent?: ReactNode;
  subtitle?: string;
  title: string;
};

const Layout = ({ children, onPrevious, subtitle, ...props }: Props) => {
  const { t } = useIntl();
  return (
    <Column textAlign="center">
      <FullPageModalContainer>
        <FullPageModalHeader
          {...props}
          {...(onPrevious && {
            backButton: { label: t('auth.pairing.back'), onClick: onPrevious },
          })}
          subtitle={subtitle || ' '}
        />
        <FullPageModalMediumSection>{children}</FullPageModalMediumSection>
      </FullPageModalContainer>
    </Column>
  );
};

export default Layout;
