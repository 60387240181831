import { rem } from 'polished';

import { theme as defaultTheme } from '@memobank/theme';

// Keep possibility to override the defaultTheme
const theme = {
  ...defaultTheme,
  color: {
    ...defaultTheme.color,
    sidebar: {
      background: '#F7F8FA',
      itemActive: '#E6E7ED',
      itemHovered: '#EEF0F5',
    },
  },
  metric: {
    ...defaultTheme.metric,
    accountsSidebarWidth: rem('240px') as '240px',
    authFormContainer: rem('392px') as '392px',
    burgerButtonHeight: rem('15px') as '15px',
    burgerButtonWidth: rem('20px') as '20px',
    mainSidebarSmallWidth: rem('72px') as '72px',
    mainSidebarWidth: rem('224px') as '224px',
    mainSidebarHorizontalRuleSmall: rem('40px') as '40px',
    mainSidebarHorizontalRuleMedium: rem('176px') as '176px',
    newSidebarWith: rem('248px') as '248px',
    newSidebarMobileWith: rem('304px') as '304px',
    onboardingCenteredContainer: rem('700px') as '700px',
    onboardingContainer: rem('1380px') as '1380px',
    onboardingHelpBeaconBottomOffset: rem('100px') as '100px',
    onboardingHelpBeaconLeftOffset: rem('40px') as '40px',
    onboardingHelpButtonBottomOffset: rem('44px') as '44px',
    onboardingHelpButtonLeftOffset: rem('40px') as '40px',
    qrCodeBorderRadius: rem('6px') as '6px',
    smallHeaderHeight: rem('56px') as '56px',
  },
  zIndex: {
    ...defaultTheme.zIndex,
    popover: 70,
  },
} as const;

export default theme;
