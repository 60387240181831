import { Enum } from '@memobank/utils';

export const APPROVAL_REQUEST_STATE = Enum(
  'APPROVED',
  'CANCELLED',
  'CONSUMED',
  'EXPIRED',
  'PENDING',
  'REJECTED',
);

export const AUTH_ERROR = Enum('ORPHAN_USER', 'SESSION_EXPIRED');

/**
 * 📜 https://ubbleai.github.io/developer-documentation/#objects
 */
export const IDENTIFICATION_STATUS = Enum(
  // Identification has only been created (user has not started the verification flow)
  'UNINITIATED',

  // User has started the verification flow
  'INITIATED',

  // User has ended the verification flow, identification-url is not usable anymore
  'PROCESSING',

  // Identification is completely processed by Ubble
  'PROCESSED',

  // User has left the identification, the identification-url is no longer usable
  'ABORTED',

  // The identification-url has expired and is no longer usable (only uninitiated and initiated identifications can become expired)
  'EXPIRED',
);

export const SETUP_STEP = Enum(
  'SETUP_PASSWORD',
  'UPLOAD_IDENTITY_PROOF',
  'ACCEPT_TERMS',
  'PAIR_2FA_DEVICE',
);
