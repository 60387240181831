import { useDevice } from '@margobank/components/device';
import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';

import CustomersMenuDropdown from './_parts/CustomersMenuDropdown';
import CustomersMenuModal from './_parts/CustomersMenuModal';

type Props = {
  currentWorkspace?: AuthenticatedCustomerDTO | GrantingFileDTO;
};

const CustomersMenu = ({ currentWorkspace }: Props) => {
  const { isScreenAtLeastM } = useDevice();
  return isScreenAtLeastM ? (
    <CustomersMenuDropdown currentWorkspace={currentWorkspace} />
  ) : (
    <CustomersMenuModal currentWorkspace={currentWorkspace} />
  );
};

export default CustomersMenu;
