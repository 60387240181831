import { createContext, useMemo, useState } from 'react';
import type { ComponentProps, ReactNode, SetStateAction } from 'react';
import { Router as RouterBase } from 'react-router-dom';

type RouteParamsType = Record<string, string>;

type RouterContextType = {
  paramsToCanonicalize: RouteParamsType;
  setParamsToCanonicalize: (valueOrCallback: SetStateAction<RouteParamsType>) => void;
};

type Props = ComponentProps<typeof RouterBase> & {
  children: ReactNode;
};

export const RouterContext = createContext<RouterContextType | undefined>(undefined);

const Router = (props: Props) => {
  const [paramsToCanonicalize, setParamsToCanonicalize] = useState<RouteParamsType>({});

  const contextValue = useMemo(
    () => ({
      paramsToCanonicalize,
      setParamsToCanonicalize,
    }),
    [paramsToCanonicalize, setParamsToCanonicalize],
  );

  return (
    <RouterContext.Provider value={contextValue}>
      <RouterBase {...props} />
    </RouterContext.Provider>
  );
};

export default Router;
