import { Card } from '@margobank/components/card';
import { css, mediaQuery, styled } from '@memobank/style';
import type { theme } from '@memobank/theme';

type Props = {
  spacing?: keyof typeof theme.spacing;
};

export default styled(Card).attrs((props: Props) => ({
  alignItems: 'center',
  spacing: props.spacing || 's3',
  textAlign: 'center',
}))`
  ${mediaQuery({ min: 'm' })(css`
    padding: ${({ theme }) => theme.spacing.s6};
  `)}
`;
