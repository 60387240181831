import type { AuthenticatedCustomerDTO } from '@margobank/components/domain/customer/types';
import type { GrantingFileDTO } from '@margobank/components/domain/grantings';
import { HoverDropdownMenu } from '@margobank/components/dropdown';

import { UserMenuItem } from '../../_styled';
import { CustomersMenuDropdownWrapper } from '../_styled';
import CustomersMenuContent from './CustomersMenuContent';
import CustomersMenuTriggerContent from './CustomersMenuTriggerContent';

type Props = {
  currentWorkspace?: AuthenticatedCustomerDTO | GrantingFileDTO;
};

const CustomersMenuDropdown = ({ currentWorkspace }: Props) => (
  <HoverDropdownMenu
    placement="left-start"
    renderMenuWrapperAs={CustomersMenuDropdownWrapper}
    renderTrigger={({ isOpen, ...triggerProps }) => (
      <UserMenuItem {...triggerProps}>
        <CustomersMenuTriggerContent />
      </UserMenuItem>
    )}
  >
    <CustomersMenuContent currentWorkspace={currentWorkspace} />
  </HoverDropdownMenu>
);

export default CustomersMenuDropdown;
