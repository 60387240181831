import { Enum } from '@memobank/utils';

export const SETUP_USER_SCREEN = Enum(
  'UPLOAD_IDENTITY_PROOF',
  'ACCEPT_TERMS',
  'PAIR_2FA_DEVICE',
  'VALIDATION_PHONE',
  'VALIDATION_EMAIL',
);

export const SETUP_USER_SCREENS = [
  SETUP_USER_SCREEN.UPLOAD_IDENTITY_PROOF,
  SETUP_USER_SCREEN.ACCEPT_TERMS,
  SETUP_USER_SCREEN.PAIR_2FA_DEVICE,
  SETUP_USER_SCREEN.VALIDATION_PHONE,
  SETUP_USER_SCREEN.VALIDATION_EMAIL,
];
