import { useState } from 'react';
import type { ReactNode } from 'react';
import { useQueryClient } from 'react-query';

import { useIntl } from '@margobank/components/intl';
import { Enum } from '@memobank/utils';

import { useLoadDevicesInformation } from 'app/auth/queries';
import { selectPhoneNumberVerificationToken, selectUser } from 'app/auth/selectors';
import { useLoadCustomers } from 'app/legacy-customer/queries';
import { useDispatch, useSelector } from 'common/store';

import { loadUser } from '../../actions';
import ConfirmPhoneNumber from './_steps/ConfirmPhoneNumber';
import FlashCode from './_steps/FlashCode';
import Installation from './_steps/Installation';
import Start from './_steps/Start';
import Validation from './_steps/Validation';

const STEP = Enum('START', 'CONFIRM_PHONE_NUMBER', 'INSTALLATION', 'FLASH_CODE', 'VALIDATION');

type Step = Enum<typeof STEP>;

type Props = {
  onAfterPairing?: () => void;
  onCancel?: () => void;
  rightComponent?: ReactNode;
};

const Pairing = ({ onAfterPairing, onCancel, rightComponent }: Props) => {
  const dispatch = useDispatch();
  const { t } = useIntl();
  const [currentStep, setCurrentStep] = useState<Step>(STEP.START);
  const user = useSelector(selectUser);
  const queryClient = useQueryClient();

  const [devicesInformation, { LoadingState: DevicesInformationLoadingState }] =
    useLoadDevicesInformation({ userId: user?.id as string }, { enabled: !!user });

  const shouldSkipInstallation = (devicesInformation || []).length > 0;
  const shouldSkipPhoneNumberConfirmation = !!useSelector(selectPhoneNumberVerificationToken);

  const handleValidationConfirmation = () => {
    // If customer is null, redirecting the user to / will redirect him back to /onboarding
    queryClient.invalidateQueries(useLoadCustomers.getQueryKey());
    dispatch(loadUser()).then(() => {
      onAfterPairing && onAfterPairing();
    });
  };

  const commonProps = {
    closeButton: onCancel && { label: t('common.actions.cancel'), onClick: onCancel },
    rightComponent,
  };

  if (!user) {
    return null;
  }

  if (!devicesInformation) {
    return <DevicesInformationLoadingState />;
  }

  return (
    <>
      {currentStep === STEP.START && (
        <Start
          {...commonProps}
          onConfirm={() =>
            setCurrentStep(
              shouldSkipPhoneNumberConfirmation ? STEP.INSTALLATION : STEP.CONFIRM_PHONE_NUMBER,
            )
          }
        />
      )}
      {currentStep === STEP.CONFIRM_PHONE_NUMBER && (
        <ConfirmPhoneNumber
          {...commonProps}
          onConfirm={() =>
            setCurrentStep(shouldSkipInstallation ? STEP.FLASH_CODE : STEP.INSTALLATION)
          }
          onPrevious={() => setCurrentStep(STEP.START)}
        />
      )}
      {currentStep === STEP.INSTALLATION && (
        <Installation
          {...commonProps}
          onConfirm={() => setCurrentStep(STEP.FLASH_CODE)}
          onPrevious={() =>
            setCurrentStep(
              shouldSkipPhoneNumberConfirmation ? STEP.START : STEP.CONFIRM_PHONE_NUMBER,
            )
          }
          userId={user.id}
        />
      )}
      {currentStep === STEP.FLASH_CODE && (
        <FlashCode
          {...commonProps}
          onConfirm={() => setCurrentStep(STEP.VALIDATION)}
          onPhoneNumberTokenExpired={() => setCurrentStep(STEP.CONFIRM_PHONE_NUMBER)}
          onPrevious={() => setCurrentStep(STEP.INSTALLATION)}
          userId={user.id}
        />
      )}
      {currentStep === STEP.VALIDATION && (
        <Validation {...commonProps} onConfirm={handleValidationConfirmation} userId={user.id} />
      )}
    </>
  );
};

export default Pairing;
